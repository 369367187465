.confetti-canvas {
  height: 0;
  width: 0;
  display: none;

  &.confetti-canvas-visible {
    display: block;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
