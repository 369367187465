@use "~styles/colors";

.image-separator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1.5em 0;
}

.image-separator__line {
  height: 1px;
  background: colors.$border-light;
  flex: 1;
}

.image-separator__image {
  display: block;
  max-width: 15px;
  margin: 0 1em;
}
