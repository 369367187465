@font-face {
  font-family: "Rubik-Light";
  src: local("Rubik-Light"),
    url("../assets/fonts/Rubik/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Regular";
  src: local("Rubik-Regular"),
    url("../assets/fonts/Rubik/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-SemiBold";
  src: local("Rubik-SemiBold"),
    url("../assets/fonts/Rubik/Rubik-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SourceSansPro-Light";
  src: local("SourceSansPro-Light"),
    url("../assets/fonts/SourceSansPro/SourceSansPro-Light.ttf")
      format("truetype");
}

@font-face {
  font-family: "SourceSansPro-Regular";
  src: local("SourceSansPro-Regular"),
    url("../assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: local("SourceSansPro-SemiBold"),
    url("../assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf")
      format("truetype");
}

@font-face {
  font-family: "Temeraire-Regular";
  src: local("Temeraire-Regular"),
    url("../assets/fonts/Temeraire/Temeraire-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Temeraire-Bold";
  src: local("Temeraire-Bold"),
    url("../assets/fonts/Temeraire/Temeraire-Bold.ttf") format("truetype");
}

 $font-heading-light: "Rubik-Light", sans-serif;
 $font-heading-regular: "Rubik-Regular", sans-serif;
 $font-heading-bold: "Rubik-SemiBold", sans-serif;

 $font-light: "SourceSansPro-Light", sans-serif;
 $font-regular: "SourceSansPro-Regular", sans-serif;
 $font-bold: "SourceSansPro-SemiBold", sans-serif;

 $font-logo-regular: "Temeraire-Regular", sans-serif;
 $font-logo-bold: "Temeraire-Bold", sans-serif;