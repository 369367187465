@use "~styles/colors";

.add-existing-selector {
  margin-bottom: 1.5em;
}
.add-existing-selector__select {
  margin-bottom: 0;

  .select__clear-indicator {
    display: none;
  }
}

.add-existing-selector__patients {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
}

.add-existing-selector__patient {
  display: flex;
  align-items: center;
  border-bottom: 1px solid colors.$border-light;
  padding-bottom: 6px;
}

.add-existing-selector__remove-btn {
  width: 40px;
  height: 36px;
  margin-left: auto;
}