@use "~styles/colors";
@use "~styles/animations";
@use "~styles/spacing";
@use "~styles/typography";
.side-nav {
  background: colors.$brand-primary-accent;
  height: 100vh;
  width: spacing.$sideNavWidth;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: animations.$baseTransition;
  z-index: 2;
  box-shadow: 0px 0px 4px 0px #0000002e;

  .side-nav__top__wrapper {
    width: 100%;
  }

  .side-nav__top {
    transition: animations.$baseTransition;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 0.5em;
    height: 42px;
    margin-bottom: 3em;
  }

  .side-nav__brand {
    transition: animations.$baseTransition;
    transition-delay: 0s;
    opacity: 0;
    visibility: hidden;
    color: colors.$text-dark;
    white-space: nowrap;
    font-family: typography.$font-logo-bold;
    font-size: 1.2em;
    color: white;
  }

  .side-nav__menu__wrapper {
    width: 100%;
  }

  .side-nav__menu {
    padding: 0;
    list-style: none;
    width: 100%;
  }

  .side-nav__expand-toggle {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1em 1em 0 1em;
    box-sizing: border-box;
    cursor: pointer;

    svg {
      width: 1.5em;
      height: 1.5em;
      transition: animations.$baseTransition;
      transform: rotate(0deg);
      fill: colors.$brand-primary;
    }
  }

  &.side-nav__expanded {
    box-shadow: colors.$boxshadow-light;
    transition: animations.$baseTransition;
    width: 155px;
    position: fixed;

    .side-nav__expand-toggle {
      svg {
        transform: rotate(180deg);
      }
    }

    .side-nav__top {
      transition-delay: 0.35s;
    }

    .side-nav__brand {
      transition-delay: 0.5s;
      opacity: 1;
      visibility: visible;
      white-space: nowrap;
    }
  }

  @media (max-width: spacing.$viewport7) {
    &.side-nav__expanded {
      width: 100%;
    }
    .side-nav__top {
      margin-top: 1em;
    }
  }
}
