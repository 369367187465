@use "~styles/colors";
@use "sass:color";

.spinner__loader {
  width: 1em;
  height: 1em;
  margin-inline-end: 1em;
  animation: rotating 1s linear infinite;

  .spinner-primary {
    fill: color.adjust(colors.$border-light, $lightness: 20%);
  }

  .spinner-secondary {
    fill: color.adjust(colors.$border-light, $lightness: 10%);
  }
}
