@import "~styles/_animations";
@import "~styles/_colors";

.checkbox_wrapper {
  transform: translateX(0);
  cursor: pointer;
  display: flex;
}

.checkbox {
  display: flex;
  margin-bottom: 1.5em;
  align-items: center;
  user-select: none;
  cursor: pointer;
  position: relative;

  &.no-margin {
    margin-bottom: 0;
  }

  &.small-margin {
    margin-bottom: 0.5em;
  }

  &.large {
    flex-direction: column;
    /* Stack checkbox and label vertically */
    align-items: center;
    /* Center items horizontally */

    .checkbox__field {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }

    .checkbox__label {
      margin-top: 0.75em;
      /* Spacing below checkbox */
      text-align: center;
      /* Center label text */
    }
  }

  .checkbox__label {
    font-weight: normal;
    font-size: 0.85em;

    &::first-letter {
      text-transform: capitalize;
    }

    &:before {
      content: "";
      visibility: hidden;
      opacity: 0;
      transition: 0.25s;
    }

    &.bold {
      font-weight: bold;
    }

    &.disabled {
      opacity: 0.6;
    }
  }

  .checkbox__field {
    position: relative;
    padding: 0.5em;
    margin: 0em;
    margin-inline-end: .5em;
    border-radius: 3px;
    border: 1px solid $border-light;
    appearance: none;
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;
    background: white;
    transition: 0.25s;

    &:hover {
      border-color: $border-dark;
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $carolina-blue;
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: rgba(0, 0, 0, 0.21) 0px 4px 17px 0px;
    }

    &.error {
      border-color: $color-danger;
    }

    &:checked {
      border-color: $carolina-blue;
      background-color: $carolina-blue;
      border-radius: 3px;

      &:before {
        content: "";
        visibility: visible;
        opacity: 1;
        position: absolute;
        inset-inline-start: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background: url("../../../assets/icons/check2.png") center/10px 10px no-repeat;
      }

      &.round {
        border-radius: 50%;
      }
    }

    &.large {
      &:checked {
        &:before {
          width: 16px;
          height: 16px;
          background: url("../../../assets/icons/check2.png") center/16px 16px no-repeat;
        }
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.1;
    }
  }

  .checkbox__error {
    color: $color-danger;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }

  .checkbox__hint {
    color: $text-grey;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }
}

.side-label {

  .checkbox {
    margin-bottom: 0;
  }

  .large {
    flex-direction: row; // Align label side-by-side
    align-items: center;
    gap: 0.5rem; // Add spacing between checkbox and label
  }

  .large {
    .checkbox__label {
      margin-top: 0;
      margin-left: 0; // Reset left margin for consistency
    }
  }
}