@use "~styles/colors";
@use "~styles/spacing";

.invite-code-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  max-width: 650px;
}

.invite-code-content__title {
  margin-bottom: 1em;
  color: colors.$text-dark;
  @media (max-width: spacing.$viewport7) {
    font-size: 1.8em;
  }
}

.invite-code-content__code {
  max-width: 250px;
  margin: 1.5em 0 2em;
}

@media print {
  .invite-code-content {
    width: 75%;
    margin: 0 auto;
    min-height: 95vh;
  }
}
