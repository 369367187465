@use "~styles/colors";

.therapist-profile-for-patients-card {
  border-radius: 6px;
  box-shadow: colors.$boxshadow-light;
  background: white;
  padding: 1em;
  margin-bottom: 1em;

  .therapist-profile-for-patients-card__title {
    margin: 0.5em 0 0;
    color: colors.$text-dark;
  }

  .therapist-profile-for-patients-card__timestamp {
    color: colors.$text-grey;
  }

  .therapist-profile-for-patients-card__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 1em 0.75em;
    border-radius: 6px;
    box-shadow: colors.$boxshadow-light;
    margin-bottom: 1em;
    max-width: 400px;
    overflow-y: auto;
  }

  .therapist-profile-for-patients-card__item-icon {
    width: 1.5em;
    height: 1.5em;

    svg {
      width: 1.5em;
      height: 1.5em;
      path {
        fill: colors.$brand-primary-accent200;
      }
    }
  }
}
