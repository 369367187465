@use "~styles/colors";
.online-session {
  background: white;
  height: 100vh;
  width: 100vw;
}

.online-session_top-bar {
  background: colors.$brand-primary-accent;
  padding: 0.5em 1em;
}

.online-session-top-bar_logo {
  margin-bottom: 0;
  .logo.white {
    p.logo__text {
      height: 20px;
      font-size: 1em;
    }
  }
}

.online-session_pre-call-body {
  height: 50vh;
  max-width: 600px;
  margin: 4em auto;
  display: flex;
  flex-direction: column;
  text-align: center;

  .text {
    margin-bottom: 2em;
  }
}
