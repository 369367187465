@use "~styles/colors";
@use "~styles/animations";

.data-table-head {
  .data-table-header-row {
    border-bottom: 1px solid colors.$border-light;
  }

  .data-table-column-header {
    text-align: start;
    color: colors.$text-grey;
    font-weight: 400;
    font-size: 0.8em;
    text-transform: uppercase;
    padding: 1em;
    transition: animations.$baseTransition;
    white-space: nowrap;

    .data-header-faded {
      opacity: 0;
      transition: animations.$baseTransition;
    }

    &:hover {
      .data-header-faded {
        opacity: 1;
      }
    }

    .data-column-sort-icon {
      width: 1em;
      height: 1.2em;
      display: inline-block;
      vertical-align: middle;

      svg {
        width: 1em;
        height: 1em;
      }
    }
  }
}
