@use "~styles/colors";

.group-selector {
  margin-bottom: 1.5em;
}
.group-selector__select {
  margin-bottom: 0;

  .select__clear-indicator {
    display: none;
  }
}

.group-selector__groups {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
}

.group-selector__group {
  display: flex;
  align-items: center;
  border-bottom: 1px solid colors.$border-light;
  padding-bottom: 6px;
}

.group-selector__remove-btn {
  width: 40px;
  height: 36px;
  margin-left: auto;
}