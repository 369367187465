.checkbox-grid {
  margin-bottom: 2em;
  &__label {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  &__row {
    margin-bottom: 1em;
  }

  &__row-label {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  &__description {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    &.vertical {
      flex-direction: column; // Vertical stacking
    }

    &.horizontal {
      flex-direction: row; // Default horizontal stacking
    }
  }

  &__item {
    display: flex;
    flex-direction: column; // Default layout: label below checkbox
    align-items: center;
    width: calc(20% - 1rem);
    text-align: center;

    &.vertical {
      flex-direction: row; // Label beside checkbox for vertical stack
      align-items: center;
      text-align: left;
      gap: 0.5rem;
      width: auto; // Adjust width for vertical layout
    }
  }
}