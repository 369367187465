@use "~styles/colors";

.invite-new-input {
  margin-bottom: 1.5em;
}

.invite-new-input__input {
  margin-bottom: 0;
}

.invite-new-input__emails {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
}

.invite-new-input__email {
  display: flex;
  align-items: center;
  border-bottom: 1px solid colors.$border-light;
  padding: 8px 0 16px 12px;
}

.invite-new-input__error {
  color: colors.$fiery-rose;
}

.invite-new-input__remove-btn {
  width: 40px;
  margin-left: auto;
}

.invite-new-input__add-btn {
  margin-top: 12px;
  width: 100px;
}