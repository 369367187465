@use "~styles/animations";

.qrcode {
  max-width: 200px;
}

.qrcode__content {
  position: relative;
  &:hover {
    .qrcode__actions {
      visibility: visible;
      opacity: 1;
    }
  }
}

.qrcode__content img {
  width: 100%;
}

.qrcode__actions {
  visibility: hidden;
  opacity: 0;
  transition: animations.$baseTransition;
  position: absolute;
  display: flex;
  inset-inline-end: 0;
  z-index: 1;
  background: white;
  gap: 1em;
  padding: 0.5em;
}
