@use "~styles/colors";
.patient-section-footer-nav {
  background: colors.$brand-primary-accent200;
  position: fixed;
  height: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: colors.$boxshadow-light-reverse;
}

.patient-section-footer-nav__back-button {
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  cursor: pointer;

  .patient-section-footer-nav__back-button--icon {
    width: 1em;
    height: 1em;
    padding-inline-start: 0.5em;
    margin-inline-end: 0.5em;

    svg {
      width: 1em;
      height: 1em;
    }
  }
}
