@use "~styles/colors.scss";

.add-links {
  &_title {
    margin: 34px 0;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
  }

  &_link {
    .divider.horizontal {
      margin: 22px auto;
    }
  }

  &_icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 12px;
    flex-shrink: 0;

    svg {
      width: 22px;
      height: 22px;
      path {
        fill: white;
      }
    }
  }

  &_linkTitle {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.2;
    color: colors.$text-dark;
  }

  &_remove-link {
    width: 40px !important;
    height: 36px;
    margin-left: auto;
    padding: 0;
    .button__icon {
      width: 16px;
      height: 16px;
      margin-inline-end: 0;
      svg {
        width: 100%;
        height: 100%;
        fill: colors.$text-grey100 !important;
      }
    }
    &:hover {
      box-shadow: none;
    }
  }

  &_submit {
    width: 228px !important;
    margin: 53px auto 0;
    padding: 10px 27px;
  }
}

.btnText {
  padding: 0;
  height: fit-content;
}