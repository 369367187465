@use "sass:color";

// Reflective Brand Colors
$ivory: #f8f6e9;
$safety-yellow: #eccf02;
$cinnamon-satin: #ce6d8b;
$plum: #913d88;
$peerple: #946195;
$carolina-blue: #4ca6e1;
$indigo-dye: #083d77;
$davys-grey: #4b4f58;
$peacock: #47c9d1;
$gold: #A98970;

// LEGACY COLORS
$dark-cornflower-blue: #373e72;
$celadon-blue: #3978a1;
$fiery-rose: #f75970;
$powder-blue: #c8f0f8;
$cowork-blue: #6985ff;
$stupid-yellow: #f5ca6f;
$happy-green: #56d7b6;
$lavender-purple: #846bf8;

$brand-primary: $peerple;
$brand-primary100: color.adjust($brand-primary, $lightness: 20%);
$brand-primary200: color.adjust($brand-primary, $lightness: 5%);
$brand-primary300: $brand-primary;
$brand-primary400: color.adjust($brand-primary, $lightness: -20%);
$brand-primary500: color.adjust($brand-primary, $lightness: -40%);

$brand-primary-accent: $indigo-dye;
$brand-primary-accent100: color.adjust($brand-primary-accent, $lightness: 20%);
$brand-primary-accent200: color.adjust($brand-primary-accent, $lightness: 10%);
$brand-primary-accent300: $brand-primary-accent;
$brand-primary-accent400: color.adjust($brand-primary-accent, $lightness: -20%);
$brand-primary-accent500: color.adjust($brand-primary-accent, $lightness: -40%);

$brand-secondary: $peacock;
$brand-secondary100: color.adjust($brand-secondary, $lightness: 40%);
$brand-secondary200: color.adjust($brand-secondary, $lightness: 20%);
$brand-secondary300: $brand-primary-accent;
$brand-secondary400: color.adjust($brand-secondary, $lightness: -20%);
$brand-secondary500: color.adjust($brand-secondary, $lightness: -40%);

$brand-secondary-accent: $safety-yellow;
$brand-secondary-accent100: color.adjust($brand-secondary-accent, $lightness: 40%);
$brand-secondary-accent200: color.adjust($brand-secondary-accent, $lightness: 20%);
$brand-secondary-accent300: $brand-secondary-accent;
$brand-secondary-accent400: color.adjust($brand-secondary-accent, $lightness: -20%);
$brand-secondary-accent500: color.adjust($brand-secondary-accent, $lightness: -40%);

$brand-danger-accent: $fiery-rose;
$brand-danger-accent100: color.adjust($brand-danger-accent, $lightness: 20%);
$brand-danger-accent200: color.adjust($brand-danger-accent, $lightness: 10%);
$brand-danger-accent300: $brand-danger-accent;
$brand-danger-accent400: color.adjust($brand-danger-accent, $lightness: -20%);
$brand-danger-accent500: color.adjust($brand-danger-accent, $lightness: -40%);

$color-info: $powder-blue;
$color-success: $brand-primary-accent400;
$color-warning: $brand-primary400;
$color-danger: $fiery-rose;

$text-dark: $brand-secondary-accent500;
$text-light: $brand-secondary-accent100;
$text-grey: $davys-grey;
$text-grey100: #8f8f8f;
$text-grey200: #acacac;

$border-light: #e3e3e3;
$border-dark: #b7c2d4;

$boxshadow-subtle: rgba(0, 0, 0, 0.15) 0px 2px 5px 0px;
$boxshadow-light: rgba(0, 0, 0, 0.05) 0px 15px 24px 0px;
$boxshadow-light-reverse: rgba(0, 0, 0, 0.05) 0px -15px 24px 0px;
$boxshadow-light-bottom: rgba(0, 0, 0, 0.04) 0px 5px 5px 0px;
$boxshadow-inset: inset 0px 0px 5px 2px rgba(2, 2, 2, 0.31);
$box-shadow-icon: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);

$background-grey: #f9f9f9;

$awesome: $happy-green;
$great: $cowork-blue;
$meh: $dark-cornflower-blue;
$sad: $fiery-rose;
$not-ok: color.adjust($sad, $lightness: 20%);

$light-blue: #4ca6e1;

$pinned-fields-main: $brand-primary-accent;
$pinned-fields-second: rgba(255, 255, 255, 0);
$pinned-fields-third: rgba(255, 255, 255, 0.25);
$text-white: #ffffff;
$pinned-fields-icon: #ebebeb;

$allColors: (
  "ivory": $ivory,
  "safety-yellow": $safety-yellow,
  "cinnamon-satin": $cinnamon-satin,
  "plum": $plum,
  "peerple": $peerple,
  "carolina-blue": $carolina-blue,
  "indigo-dye": $indigo-dye,
  "davys-grey": $davys-grey,
  "peacock": $peacock,
  "dark-cornflower-blue": $dark-cornflower-blue,
  "brand-primary": $brand-primary,
  "brand-primary100": $brand-primary100,
  "brand-primary200": $brand-primary200,
  "brand-primary300": $brand-primary300,
  "brand-primary400": $brand-primary400,
  "brand-primary500": $brand-primary500,
  "brand-primary-accent": $brand-primary-accent,
  "brand-primary-accent100": $brand-primary-accent100,
  "brand-primary-accent200": $brand-primary-accent200,
  "brand-primary-accent300": $brand-primary-accent300,
  "brand-primary-accent400": $brand-primary-accent400,
  "brand-primary-accent500": $brand-primary-accent500,
  "brand-secondary": $brand-secondary,
  "brand-secondary100": $brand-secondary100,
  "brand-secondary200": $brand-secondary200,
  "brand-secondary300": $brand-secondary300,
  "brand-secondary400": $brand-secondary400,
  "brand-secondary500": $brand-secondary500,
  "brand-secondary-accent": $brand-secondary-accent,
  "brand-secondary-accent100": $brand-secondary-accent100,
  "brand-secondary-accent200": $brand-secondary-accent200,
  "brand-secondary-accent300": $brand-secondary-accent300,
  "brand-secondary-accent400": $brand-secondary-accent400,
  "brand-secondary-accent500": $brand-secondary-accent500,
  "brand-danger-accent": $brand-danger-accent,
  "brand-danger-accent100": $brand-danger-accent100,
  "brand-danger-accent200": $brand-danger-accent200,
  "brand-danger-accent300": $brand-danger-accent300,
  "brand-danger-accent400": $brand-danger-accent400,
  "brand-danger-accent500": $brand-danger-accent500,
  "color-info": $color-info,
  "color-success": $color-success,
  "color-warning": $color-warning,
  "color-danger": $color-danger,
);

@each $colorName, $colorValue in $allColors {
  .text-#{$colorName} {
    color: $colorValue;
  }

  .bg-#{$colorName} {
    background-color: $colorValue;
  }
}

$transparencies: (
  "0": 0,
  "10": 0.1,
  "20": 0.2,
  "30": 0.3,
  "40": 0.4,
  "50": 0.5,
  "60": 0.6,
  "70": 0.7,
  "80": 0.8,
  "90": 0.9,
  "100": 1,
);

.bg-white {
  background-color: white;
}

@each $transparencyName, $transparencyValue in $transparencies {
  .bg-white-#{$transparencyName} {
    background-color: rgba(255, 255, 255, $transparencyValue);
  }
}

.bg-blur {
  backdrop-filter: blur(10px);
}

@for $i from 1 through 10 {
  .bg-blur-#{$i} {
    backdrop-filter: blur($i + px);
  }
}

.shadow {
  box-shadow: $boxshadow-subtle;
}

.shadow-subtle {
  box-shadow: $boxshadow-subtle;
}

.shadow-light {
  box-shadow: $boxshadow-light;
}

.shadow-light-reverse {
  box-shadow: $boxshadow-light-reverse;
}

.shadow-light-bottom {
  box-shadow: $boxshadow-light-bottom;
}

.shadow-inset {
  box-shadow: $boxshadow-inset;
}