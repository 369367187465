@use "~styles/colors";
@use "~styles/animations";

.table-footer-row {
    transition: animations.$baseTransition;
    border-top: 1px solid colors.$border-light;
    cursor: pointer;  
}

.table-footer-cell {
    padding: 1em 0.75em;
}