@use "~styles/colors";
.group-page-stats {
  display: flex;
  align-items: center;

  .group-page-stats__wrapper {
    background: white;
    margin-inline-start: 1em;
    padding: 0.5em;
    border-radius: 0.5em;
    box-shadow: colors.$boxshadow-light;
  }

  .group-page-stats__count {
    margin-top: 0;
    margin-bottom: 0;
  }

  .group-page-stats__total {
    color: colors.$brand-primary;
  }
}

.note_delete_btn {
  font-size: 0.75em;
  font-weight: 600;
  color: colors.$carolina-blue;
  fill: colors.$carolina-blue;
  svg {
    width: 16px;
    height: 16px;
  }
}

.react-datepicker-popper {
  transform: translateY(73px) !important;
}
