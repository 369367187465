@use "~styles/colors";

.mobile-footer-popup__overlay {
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.mobile-footer-popup {
  background: white;
  width: 100%;
  padding: 1em;
  border-radius: 6px 6px 0px 0px;
  box-shadow: colors.$boxshadow-light-reverse;
}
