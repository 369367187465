:root {
  --grid-gap: var(--border-radius);
}

.grid {
  display: grid;
  width: 100%;
  height: 100%;
  &.col {
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);

    &.fit {
      grid-auto-columns: max-content;
    }
  }

  &.row {
    grid-auto-flow: row;
    grid-auto-rows: minmax(0, 1fr);

    &.fit {
      grid-auto-rows: max-content;
    }
  }
}

.grid > * {
  min-width: 0 !important;
  min-height: 0 !important;
}
@for $i from 1 through 12 {
  .col-#{$i} {
    grid-template-columns: repeat(#{$i}, 1fr);
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}
.wh-100,
.fill {
  width: 100%;
  height: 100%;
}
.vw-100 {
  width: 100vw;
}
.vh-100 {
  height: 100vh;
}

.vwh-100 {
  width: 100vw;
  height: 100vh;
}
.auto-flow-columns {
  grid-auto-flow: column;
}

.auto-flow-rows {
  grid-auto-flow: row;
}

.grid-gap {
  grid-gap: var(--grid-gap);
}

.grid-gap-0 {
  grid-gap: 0;
}

.grid-gap-1 {
  grid-gap: 0.25rem;
}

.grid-gap-2 {
  grid-gap: 0.5rem;
}

.grid-gap-3 {
  grid-gap: 1rem;
}

.grid-gap-4 {
  grid-gap: 1.5rem;
}

.grid-gap-5 {
  grid-gap: 3rem;
}

.row-gap-0 {
  row-gap: 0;
}

.row-gap-1 {
  row-gap: 0.25rem;
}

.row-gap-2 {
  row-gap: 0.5rem;
}

.row-gap-3 {
  row-gap: 1rem;
}

.row-gap-4 {
  row-gap: 1.5rem;
}

.row-gap-5 {
  row-gap: 3rem;
}

.column-gap-0 {
  column-gap: 0;
}

.column-gap-1 {
  column-gap: 0.25rem;
}

.column-gap-2 {
  column-gap: 0.5rem;
}

.column-gap-3 {
  column-gap: 1rem;
}

.column-gap-4 {
  column-gap: 1.5rem;
}

.column-gap-5 {
  column-gap: 3rem;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: start;
}

.align-items-end {
  align-items: end;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-center {
  align-content: center;
}

.align-content-start {
  align-content: start;
}

.align-content-end {
  align-content: end;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-auto {
  justify-self: auto;
}

.align-self-center {
  align-self: center;
}

.align-self-start {
  align-self: start;
}

.align-self-end {
  align-self: end;
}

.align-self-auto {
  align-self: auto;
}

.fit-content {
  width: fit-content;
  height: fit-content;
}

.max-content {
  width: max-content;
  height: max-content;
}

.mc-w {
  width: max-content;
}

.mc-h {
  height: max-content;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

$spacers: (
  0: 0 !important,
  1: 0.25rem !important,
  2: 0.5rem !important,
  3: 1rem !important,
  4: 1.5rem !important,
  5: 3rem !important,
);

@each $key, $value in $spacers {
  .p-#{$key} {
    padding: $value;
  }
  .pt-#{$key} {
    padding-top: $value;
  }
  .pb-#{$key} {
    padding-bottom: $value;
  }
  .ps-#{$key} {
    padding-inline-start: $value;
  }
  .pe-#{$key} {
    padding-inline-end: $value;
  }
  .px-#{$key} {
    padding-inline-start: $value;
    padding-inline-end: $value;
  }
  .py-#{$key} {
    padding-top: $value;
    padding-bottom: $value;
  }
  .m-#{$key} {
    margin: $value;
  }
  .mt-#{$key} {
    margin-top: $value;
  }
  .mb-#{$key} {
    margin-bottom: $value;
  }
  .ms-#{$key} {
    margin-inline-start: $value;
  }
  .me-#{$key} {
    margin-inline-end: $value;
  }
  .mx-#{$key} {
    margin-inline-start: $value;
    margin-inline-end: $value;
  }
  .my-#{$key} {
    margin-top: $value;
    margin-bottom: $value;
  }
}

.center-items {
  place-items: center;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

$overflows: (
  visible: visible,
  hidden: hidden,
  scroll: scroll,
  auto: auto,
);
@each $key, $value in $overflows {
  .overflow-#{$key} {
    overflow: $value !important;
  }
  .overflow-x-#{$key} {
    overflow-x: $value !important;
  }
  .overflow-y-#{$key} {
    overflow-y: $value !important;
  }
}

.border-box {
  box-sizing: border-box;
}

.content-box {
  box-sizing: content-box;
}
