@use "~styles/colors.scss";

.container {
  padding: 4px 2px;
}

.title {
  margin-top: 5px;
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 600;
  line-height: 0.9;
  color: colors.$dark-cornflower-blue;
}

.description {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;

  p {
    margin-top: 18px;
  }
}

.buttons_container {
  display: flex;
  margin-bottom: 12px;

  .assignment-card_btn {
    padding: 13px 6px;

    svg {
      width: 20px;
      height: 20px;
      fill: colors.$carolina-blue;
    }

    .button__icon {
      margin-inline-end: 6px;
    }
  }
}

.qr_code_image {
  width: 203px;
  height: 204px;
  margin-bottom: 12px;
}

.share-link {
  font-size: 16px;
  line-height: 1.5;
  color: colors.$carolina-blue;
}

.spinner-container {
  width: 100%;
  margin-top: 50px;
}