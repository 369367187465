@use "~styles/colors";
@use "~styles/animations";
@use "~styles/typography";
@use "sass:color";

.button {
  background: colors.$brand-primary;
  background: linear-gradient(
    to right,
    colors.$brand-primary,
    colors.$brand-primary200,
    colors.$brand-primary,
    colors.$brand-primary
  );
  background-size: 200%;
  background-position: left;
  transition: animations.$baseTransition;
  border: 1px solid colors.$brand-primary;
  border-radius: 3px;
  padding: 1em 2em;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  &.big {
    padding: 0.78em 2.9em;
  }
  &.small {
    padding: 0.65em 1.25em;
    font-size: 0.75em;
  }
  &.tiny {
    padding: 0.5em 1em;
    font-size: 0.65em;
  }

  &.thin {
    padding: 10px 27px;
  }

  .button__icon {
    svg {
      fill: white;
    }
  }

  &:hover {
    background-position: right;
    box-shadow: colors.$boxshadow-light;
  }

  &:disabled {
    background: colors.$border-light;
    border-color: colors.$border-light;
  }

  &:active,
  &:focus {
    background: colors.$brand-primary100;
    border-color: colors.$brand-primary100;
  }

  &.secondary {
    background: white;
    color: colors.$brand-primary-accent300;
    border-color: colors.$brand-primary-accent300;

    .button__icon {
      svg {
        fill: colors.$brand-primary-accent300;
      }
    }

    &:hover {
      background: color.adjust(colors.$brand-secondary300, $lightness: 3%);
      border-color: color.adjust(colors.$brand-secondary300, $lightness: 3%);
      box-shadow: colors.$boxshadow-light;
      color: white;

      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:disabled {
      background: colors.$border-light;
      border-color: colors.$border-light;
      color: white;
      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:active,
    &:focus {
      background: white;
      border-color: colors.$brand-primary-accent200;
      color: colors.$brand-primary-accent200;
      .button__icon {
        svg {
          fill: colors.$brand-primary-accent200;
        }
      }
    }
  }

  &.secondary-bland {
    flex-shrink: 0;
    background: white;
    color: colors.$brand-primary;
    border-color: colors.$brand-primary;

    .button__icon {
      svg {
        fill: colors.$brand-primary;
      }
    }

    &:hover {
      background: colors.$brand-primary200;
      border-color: colors.$brand-primary200;
      box-shadow: colors.$boxshadow-light;
      color: white;

      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:disabled {
      background: colors.$border-light;
      border-color: colors.$border-light;
      color: white;
      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:active,
    &:focus {
      background: white;
      border-color: colors.$brand-primary-accent300;
      color: colors.$brand-primary-accent300;
      .button__icon {
        svg {
          fill: colors.$brand-primary-accent300;
        }
      }
    }
  }

  .button_value {
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
  }

  &.danger {
    margin-left: 15px;
    background: colors.$color-danger;
    color: white;
    border-color: colors.$color-danger;

    .button__icon {
      svg {
        fill: white;
      }
    }

    .button_value {
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      background: color.adjust(colors.$color-danger, $lightness: 3%);
      border-color: color.adjust(colors.$color-danger, $lightness: 3%);
      box-shadow: colors.$boxshadow-light;
      color: white;

      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:disabled {
      background: colors.$border-light;
      border-color: colors.$border-light;
      color: white;
      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:active,
    &:focus {
      background: white;
      border-color: colors.$color-danger;
      color: colors.$color-danger;
      .button__icon {
        svg {
          fill: colors.$color-danger;
        }
      }
    }
  }

  &.accent {
    background: colors.$brand-primary-accent;
    color: white;
    border-color: colors.$brand-primary-accent;

    .button__icon {
      svg {
        fill: white;
      }
    }

    &:hover {
      background: color.adjust(colors.$brand-primary-accent, $lightness: 5%);
      border-color: color.adjust(colors.$brand-primary-accent, $lightness: 3%);
      box-shadow: colors.$boxshadow-light;
      color: white;

      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:disabled {
      background: colors.$border-light;
      border-color: colors.$border-light;
      color: white;

      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:active,
    &:focus {
      background: white;
      border-color: colors.$brand-primary-accent;
      color: colors.$brand-primary-accent;

      .button__icon {
        svg {
          fill: colors.$brand-primary-accent;
        }
      }
    }
  }

  &.pill {
    border-radius: 4em;
  }

  &.full-width {
    width: 100%;
  }

  &.one-fifty-width {
    width: 150px;
  }

  &.transparent {
    background: transparent;
    border: 1px solid transparent;
  }

  .button__loader {
    width: 1em;
    height: 1em;
    margin-inline-end: 1em;
    animation: rotating 1s linear infinite;

    .spinner-primary {
      fill: color.adjust(colors.$border-light, $lightness: 20%);
    }

    .spinner-secondary {
      fill: color.adjust(colors.$border-light, $lightness: 10%);
    }
  }

  .button__icon {
    width: 1.5em;
    height: 1.5em;
    margin-inline-end: 1em;

    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }

  &.save-settings {
    margin: 0 auto 24px;
    border-radius: 22px;
    box-shadow: colors.$box-shadow-icon;
    border: 0;
  }

  &.assignment-card_submissions {
    position: relative;
    margin-bottom: 0.625em;
    padding: 0 !important;
    font-size: 0.75em;
    line-height: 2;
    text-transform: none;
    color: colors.$light-blue;
    background: transparent;
    border: 0.0625em solid transparent;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 0.0625em;
      background-color: colors.$light-blue;
      bottom: 0.3125em;
      left: 0;
    }
  }

  &.assignment-card_btn {
    padding: 0.625em 0.5em 0.625em 0.25em;
    font-size: 0.75em;
    line-height: 2;
    text-transform: none;
    color: colors.$light-blue;
    fill: colors.$carolina-blue;
    background: transparent;
    border: 0.0625em solid transparent;
    svg {
      width: 1.5em;
      height: 1.25em;
    }
    span {
      margin-left: 0.3125em;
    }
  }

  &.remove {
    color: colors.$text-grey100;
    fill: colors.$text-grey100;
  }

  &.button-icon {
    padding: 13px 3px;
    font-size: 13px;
    color: colors.$carolina-blue;
    background: transparent;
    border: 1px solid transparent;
    svg {
      width: 22px;
      height: 16px;
      fill: colors.$carolina-blue;
    }
    span {
      margin-left: 5px;
      line-height: 1.84;
    }
    &_hide {
      svg {
        width: 26px;
      }
    }
    &_change {
      margin-left: auto;
      padding: 11px 16px;
    }
    &.regular-size {
      padding: 11px;
      svg {
        height: 22px;
      }
      .button__icon {
        margin-inline-end: 0;
      }
    }
    &:hover {
      box-shadow: none;
    }
  }

  &.submit-button {
    width: 228px !important;
    margin: 0px auto;
    padding: 10px 27px;
  }  
 // Fixed to the bottom of the window
  &.b-fixed {
    position: fixed;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
}
