// Viewport sizes
// in place of "mobile", "tablet" and "desktop"
// per this article: https://ricostacruz.com/til/css-media-query-breakpoints
$viewport4: 480px;
$viewport7: 768px;
$viewport9: 992px;
$viewport12: 1200px;

// Column Widths
// Based on an 8 column grid
$c1: 12.5%;
$c2: 25%;
$c3: 37.5%;
$c4: 50%;
$c5: 62.5%;
$c6: 75%;
$c7: 87.5%;
$c8: 100%;

$zIndex: 10;

$topNavHeight: 80px;
$sideNavWidth: 56px;

$padding: 16px;

$app-content-vertical-padding-mobile: 1rem;
$app-content-horizontal-padding-mobile: 0.25rem;
