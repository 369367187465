@use "~styles/colors";

.dropdown-menu {
  position: relative;

  .dropdown-menu__title {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      width: 1.3em;
      height: 1.3em;

      path {
        fill: colors.$brand-primary-accent300;
      }
    }

    .dropdown-menu__title-icon {
      margin-inline-start: 0.5em;
      width: 1em;
      height: 1em;
      display: inline-block;
      vertical-align: middle;

      svg {
        width: 1em;
        height: 1em;
        path {
          fill: colors.$brand-primary-accent300;
        }
      }
    }
  }

  .dropdown-menu__body {
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    height: 0;
    width: 120px;
    background: white;
    position: absolute;
    top: 25px;
    right: 0px;
    z-index: 99;
    padding: 0.5em 0em 1em;
    box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.23);
    border-radius: 0px 0px 4px 4px;
    border-top: 2px solid colors.$brand-primary-accent300;
    transition: 0.25s;

    &.dropdown-menu__body-open {
      opacity: 1;
      visibility: visible;
      height: auto;
      padding: 1em;
    }
  }

  &.outlined {
    padding: 0.25em 0.5em;
    border: 1px solid #e3e3e3;
    border-radius: 2px;
    margin: 0 0.5em;
    position: relative;
    font-weight: bold;
    font-size: 0.85em;
  }
}
