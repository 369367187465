@use "~styles/colors";
@use "~styles/animations";
@use "~styles/spacing";
@use "sass:color";

.side-nav-item {
  transition: animations.$baseTransition;
  padding: 1em 0.5em 1em 1em;
  cursor: pointer;
  border-inline-end: 2px solid transparent;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  height: 50px;
  margin-bottom: 0.5em;
  color: white;

  &:hover,
  &.active {
    border-inline-end: 4px solid colors.$brand-secondary-accent;

    .side-nav-item__icon {
      svg {
        fill: colors.$brand-secondary-accent;
      }
    }

    .side-nav-item__label {
      color: white;
    }

    @media (max-width: spacing.$viewport7) {
      background: color.adjust(colors.$brand-secondary200, $lightness: 25%);
    }
  }
}

.side-nav-item__icon {
  width: 1.5em;
  height: 1.5em;

  svg {
    width: 1.5em;
    height: 1.5em;
    fill: white;
  }
}

.side-nav-item__label {
  transition: animations.$baseTransition;
  opacity: 0;
  visibility: hidden;
  width: 0px;
  margin-inline-start: 0;
  transform: scale(0);
  color: white;
  white-space: nowrap;
}

.side-nav-item__dot {
  height: 0.4em;
  width: 0.4em;
  background: colors.$color-danger;
  border-radius: 100%;
  position: absolute;
  inset-inline-end: 0.5em;
  top: 0.5em;
  box-shadow: 0px 0px 4px 1px colors.$color-danger;
}

.side-nav-item__expanded {
  .side-nav-item__label {
    opacity: 1;
    visibility: visible;
    width: auto;
    margin-inline-start: 1em;
    transform: scale(1);
    font-size: 0.8em;
    white-space: nowrap;
  }
}
