@use "~styles/spacing";
@use "~styles/colors";

.left-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  order: 1; // Default order for LTR
}

.right-side {
  min-height: 100vh;
  height: auto;
  box-sizing: border-box;
  background: colors.$ivory;
  background-image: url("../../assets/images/auth-background.png");
  background-repeat: no-repeat;
  background-position: right top;
  order: 2; // Default order for LTR

  &.full-height {
    position: fixed;
    right: 0;
  }
}

/* Adjust for RTL */
[dir="rtl"] .flex {
  flex-direction: row-reverse; // Reverse the order of flex items in RTL
}

[dir="rtl"] .left-side {
  order: 2; // Move left-side to the second position in RTL
}

[dir="rtl"] .right-side {
  order: 1; // Move right-side to the first position in RTL
  background-position: left top; // Adjust background for RTL
}

@media (max-width: spacing.$viewport7 - 1) {
  .left-side {
    flex: 1;
    height: 100vh;
    background: colors.$ivory;
    background-image: url("../../assets/images/auth-background.png");
    background-repeat: no-repeat;
    background-position: center top;
  }

  [dir="rtl"] .left-side {
    background-position: center top; // Same adjustment for mobile
  }

  .auth-content {
    background: white;
    padding: 2em;
    border-radius: 8px;
  }
}