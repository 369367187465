@use "~styles/colors";

.file-dropzone {
  min-width: 350px;
  max-width: 380px;
  border: 2px dashed #ccc;
  padding: 20px;
  transition: border 0.3s ease-in-out;
  text-align: center;
  border-radius: 4px;

  &.drop-zone-only {
    min-width: 0;
    width: auto;
  }
  &.not-drop-zone-only {
    margin-top: 0.5em;
  }
}

.file-dropzone--isActive {
  border: 2px dashed #0095f6;
  background-color: #f3f4f6;
}

.file-uploader-container {
  display: flex;
  flex-direction: column;
}

.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.selected-file {
  background: white;
  border: 2px solid transparent;
  padding: 0;
  width: 100%;
  max-width: 450px;
}

.selected-file__inner {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 1em;
  display: flex;
  align-items: center;

  &.with-mb {
    margin-bottom: 1.5em;
    margin-top: 0.5em;
  }

  .selected-file__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-icon {
    min-width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: black;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 1em;

    svg {
      width: 25px;
      height: 25px;

      path {
        fill: white;
      }
    }
  }
}

.file-uploader-container__icon {
  margin-bottom: 0.5em;
  svg {
    width: 2em;
    height: 2em;
    path {
      fill: colors.$indigo-dye;
    }
  }
}

.file-uploader-container__title {
  margin-top: 1.5em;
}

.file-uploader-container__message {
  margin-top: 0.5em;
  margin-bottom: 0;
  margin-inline-start: 0.15em;
}

.file-uploader-container {
  .toggle-default {
    margin-bottom: 20px;
  }
}
