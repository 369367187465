@use "~styles/colors";
@use "~styles/spacing";

.top-nav-wrapper {
  position: fixed;
  width: 100%;
  max-width: 100%;
  z-index: 1;
  top: 0;
  right: 0;
  left: 155px;
  box-shadow: colors.$boxshadow-subtle;
  @media (max-width: spacing.$viewport7) {
    left: 0;
  }

  .p-y-1 {
    display: flex;
  }

  .button {
    margin-left: 12px;
    border: 1px solid transparent;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.top-nav {
  padding: 0.75em 2em 0em 2em;
  background: white;

  width: calc(100% - 155px - 4em);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: spacing.$viewport7) {
    padding: 0em;
    width: 100%;
    max-width: 100%;
  }

  flex-wrap: wrap-reverse;

  &.top-nav-only {
    padding-bottom: 0.75em;
  }
}

.bottom-nav {
  padding: 0em 2em;
  padding-bottom: 0em;
  background: white;
  width: calc(100% - 155px - 4em);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: spacing.$viewport7) {
    padding: 1em;
    width: calc(100% - 2em);
    max-width: 100%;
  }
}

.top-nav__help-icon {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 1em;
    height: 1em;

    path {
      fill: colors.$brand-primary-accent;
    }
  }
}

.top-r,
.bot-r {
  justify-content: end;
}

.top-l,
.top-r,
.bot-l,
.bot-r {
  @media (max-width: spacing.$viewport7) {
    justify-content: flex-start;
    padding: 1em 1em;
  }
}

.bot-r {
  padding-top: 0em;
  align-items: flex-end;
  display: flex;
}

.bot-l {
  justify-content: flex-start;
  display: flex;
  align-self: end;
  @media (max-width: spacing.$viewport7) {
    padding: 0;
  }
}
