@use "~styles/colors";

.alert-bar {
  padding: 1em 0.5em;
  font-size: 0.8em;

  &.alert-bar-info {
    background: colors.$color-info;
  }

  &.alert-bar-warn {
    background: colors.$color-warning;
  }

  &.alert-bar-error {
    background: colors.$color-danger;
  }

  &.alert-bar-success {
    background: colors.$color-success;
  }

  &.rounded {
    border-radius: 4px;
  }

  &.padded {
    padding: 0.75em 1em;
    margin-bottom: 2em;
  }
}
