@use "~styles/colors";

.group-member-buttons {
  display: flex;
  gap: 24px;
}

.group-member-buttons_btn {
    font-size: 0.75em;
    font-weight: 600;
    color: colors.$carolina-blue;
    fill: colors.$carolina-blue;
    svg {
      width: 16px;
      height: 16px;
    }
}

