.emoji-dropdown-container {
  position: relative;
  display: inline-block;
}

.emoji-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  font-size: 24px;
}

.emoji-picker-dropdown {
  position: absolute;
  top: 0px; // Adjust based on trigger height
  left: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}