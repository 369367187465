@use "~styles/colors";

.configure {
    &_container {
        h4 {
            font-size: 22px;
            font-weight: 500;
            color: colors.$text-grey100;
        }

        .fields-box {
            padding: 0;
            box-shadow: none;
        }

        .save-settings {
            margin-top: 30px;
        }
    }
}