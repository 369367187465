@use "~styles/colors";
@use "~styles/animations";
@use "~styles/spacing";

.collection_wrapper {
  position: relative;
  width: 295px;
  height: 308px;
  box-sizing: border-box;
  margin: 28px 11px 0 11px;
  padding: 14px 17px 17px;
  box-shadow: 0px 2px 6px 0px #00000026;

  &:hover {
    .collection_actions {
      visibility: visible;
    }
  }

  @media screen and (max-width: spacing.$viewport7) {
    margin: 16px;
  }
}

.collection_header {
  margin-bottom: 12px;

  &_icon {
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    margin-inline-end: 5px;
    border-radius: 50%;
    background: #000000;

    svg {
      fill: colors.$text-white;
    }
  }

  &_icon-checked {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-inline-end: 29px;
    margin-bottom: 24px;
    border-radius: 4px;
    background: colors.$carolina-blue;
    svg {
      width: 16px;
      height: 16px;
      fill: white;
    }
  }

  .collection-card-clamped-title {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    color: #888888;
  }
}

.collection_image-container {
  position: relative;
  width: 261px;
  height: 145px;
  margin-bottom: 8px;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #bdbdbd;
  &::before {
    position: absolute;
    content: "";
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6px 6px 0 0;
    border: 1px solid #bdbdbd;
    background: colors.$text-white;
    border-bottom: 0;
    width: 91%;
    height: 8px;
    top: -10px;
  }
  &::after {
    position: absolute;
    content: "";
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6px 6px 0 0;
    border: 1px solid #bdbdbd;
    background: colors.$text-white;
    border-bottom: 0;
    width: 95%;
    height: 4px;
    top: -6px;
  }
}

.collection_image_wrapper {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
}

.collection_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.collection_sticker {
  position: absolute;
  bottom: 11px;
  right: 10px;
  padding: 4px 11px;
  background: colors.$carolina-blue;

  span {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    border-radius: 2px;
    color: colors.$text-white;
  }
}

.collection-card-clamped-description {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  color: #00000073;
}

.collection_actions {
  visibility: hidden;
  position: absolute;
  top: 16px;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  background: #fff;
  transition: animations.$baseTransition;

  &_action {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-inline-end: 1em;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
