@use "~styles/colors";
@use "~styles/animations";
@use "~styles/spacing";

.mobile-bottom-nav-item {
  transition: animations.$baseTransition;
  padding: 1em 0.5em 1em 1em;
  cursor: pointer;
  border-top: 2px solid transparent;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  height: 56px;
  justify-content: center;

  &:hover,
  &.active {
    border-top: 4px solid colors.$brand-primary-accent;

    .mobile-bottom-nav-item__icon {
      svg {
        fill: colors.$brand-primary;
      }
    }

    .mobile-bottom-nav-item__label {
      color: colors.$brand-primary;
    }
  }
}

.mobile-bottom-nav-item__icon {
  width: 1.5em;
  height: 1.5em;

  svg {
    width: 1.5em;
    height: 1.5em;
    fill: colors.$brand-primary;
  }
}

.mobile-bottom-nav-item__label {
  transition: animations.$baseTransition;
  opacity: 0;
  visibility: hidden;
  width: 0px;
  margin-inline-start: 0;
  transform: scale(0);
  color: colors.$brand-primary;
  white-space: nowrap;
}

.mobile-bottom-nav-item__dot {
  height: 0.4em;
  width: 0.4em;
  background: colors.$color-danger;
  border-radius: 100%;
  position: absolute;
  inset-inline-end: 0.3em;
  top: 0.5em;
  box-shadow: 0px 0px 4px 1px colors.$color-danger;
}
