.tag {
  background-color: #9e9e9e;
  padding: 0.25em 0.75em;
  border-radius: 16px;
  color: white;
  display: inline-block;

  &.tiny {
    font-size: 0.8em;
    border-radius: 4px;
    font-weight: bold;
  }
}
