@use "~styles/colors";
@use "~styles/spacing";

.assignments-for-patients {
  display: flex;
  box-shadow: colors.$boxshadow-light;
  box-sizing: border-box;
  background: white;
  border-radius: 4px;

  .assignments-for-patients_column {
    padding: 1em;
    position: relative;
    box-sizing: border-box;
    width: 275px;
  }

  .assignments-for-patients-sections_column {
    padding: 1em;
    position: relative;
    box-sizing: border-box;
    width: 275px;
    border-inline-start: 1px solid #e3e3e3;
    border-inline-end: 1px solid #e3e3e3;
  }

  .assignments-for-patients-fields_column {
    padding: 1em;
    position: relative;
    box-sizing: border-box;
    flex: 1;
  }

  @media (max-width: spacing.$viewport7) {
    &.assignments-for-patients {
      background: none;
      box-shadow: none;
    }
    &.assignments-focused {
      .assignments-for-patients_column {
        width: 100%;
        padding: 0;
      }

      .assignments-for-patients-sections_column {
        display: none;
      }

      .assignments-for-patients-fields_column {
        display: none;
      }
    }

    &.sections-focused {
      .assignments-for-patients_column {
        display: none;
      }

      .assignments-for-patients-sections_column {
        width: 100%;
        padding: 0;
        border-inline-start: none;
        border-inline-end: none;
      }

      .assignments-for-patients-fields_column {
        display: none;
      }
    }

    &.fields-focused {
      .assignments-for-patients_column {
        display: none;
      }

      .assignments-for-patients-sections_column {
        display: none;
      }

      .assignments-for-patients-fields_column {
        width: 100%;
        padding: 0;
      }
    }
  }
}
