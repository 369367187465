@use "src/styles/animations";
@use "src/styles/colors";
@use "~styles/typography";
@use "sass:color";

.custom-mood-scale-field {
  --emoji-size: 50px;

  .label {
    margin-bottom: 0.5em;
    font-weight: normal;
  }

  .description {
    margin-bottom: 1em;
    font-size: 0.8em;
    color: colors.$text-grey;
  }

  .emojis {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-around;
    row-gap: 1rem;
    column-gap: 0.5rem;
    margin-top: 1rem;

    .emoji-wrapper {
      display: grid;
      position: relative;
      grid-template-columns: 1fr;
      grid-template-rows: max-content 1fr;
      justify-content: center;
      align-content: center;
      justify-items: center;
      align-items: center;

      &.selected {
        .emoji-box {
          background-color: colors.$light-blue !important;
          border-color: colors.$light-blue !important;
          box-shadow: colors.$boxshadow-subtle;
          color: white;
        }
        .emoji-label {
          font-weight: 700;
        }
      }

      &:hover {
        .emoji-box {
          background-color: colors.$background-grey;
          color: white;
        }
      }

      gap: 0.5rem;

      .emoji-box {
        width: var(--emoji-size);
        height: var(--emoji-size);
        line-height: var(--emoji-size);
        border: 1.5px solid colors.$border-light;
        cursor: pointer;
        text-align: center;
        box-sizing: border-box;
        border-radius: 0.2rem;
        background-color: white;
        padding: 0;
        font-size: 1.7rem;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      }

      .emoji-label {
        font-size: 0.8rem;
      }
    }
  }
}

.editable-field {
  margin-bottom: 1.5em;
  position: relative;

  .pre-filled-field {
    display: block;
    font-size: 0.8em;
    opacity: 0;
    color: rgba(148, 97, 149, 0.75);
    font-style: italic;
    clear: both;
    transition: all 0.3s ease;
  }

  .pre-filled-field.show {
    opacity: 1;
    right: 0;
    transition: all 0.3s ease;
  }
}

.string-field-new,
.textarea-field-new {
  display: flex;
  flex-direction: column;

  .field__label {
    margin-bottom: 0.5em;
    font-weight: normal;
  }

  .field__field {
    padding: 1em;
    border-radius: 3px;
    border: 1px solid colors.$border-light;
    font-family: typography.$font-regular;

    &::placeholder {
      color: colors.$border-light;
      transition: animations.$baseTransition;
    }

    &:hover {
      border-color: colors.$border-dark;

      &::placeholder {
        color: colors.$border-dark;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: colors.$brand-primary-accent200;

      &::placeholder {
        opacity: 0;
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: colors.$boxshadow-light;
    }

    &.error {
      border-color: colors.$color-success;
    }
  }
}

.thought-distortions {

  button {
    font-size: unset;
    font-weight: unset;
    padding: 0;
    border: none;
    margin: 0;
    cursor: pointer;

    &:hover,
    &.select__option--is-selected {
      background: color.adjust(colors.$powder-blue, $lightness: 10%);
    }
  }
}

.thought-distortion-option {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid colors.$border-light;
  cursor: pointer;
  padding: 0.5em 1em 1em;

  &:hover,
  &.select__option--is-selected {
    background: color.adjust(colors.$powder-blue, $lightness: 10%);
  }

  .thought-distortion-option__label {
    font-weight: 600;
    color: colors.$text-dark;
    margin-bottom: 0.15em;
  }

  .thought-distortion-option__explanation {
    font-weight: 300;
    color: colors.$text-grey;
    font-size: 1em;
    margin-bottom: 0.75em;
  }

  .thought-distortion-option__example {
    font-size: 0.8em;
    font-style: italic;
    color: colors.$brand-secondary-accent400;
    display: flex;
  }

  .thought-distortion-option__example--icon {
    width: 0.8em;
    height: 0.8em;
    margin-inline-end: 0.8em;
    margin-bottom: 0.2em;

    svg {
      width: 0.8em;
      height: 0.8em;

      path {
        fill: colors.$text-grey;
      }
    }
  }
}

.range-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  position: relative;

  .range-field__label {
    margin-bottom: 0.5em;
  }

  .range-field__input-wrapper {
    position: relative;
    height: 40px;
    margin-top: 1.5em;
    width: 100%;

    .range-field__bubble {
      position: absolute;
      background: white;
      border: 1px solid colors.$brand-primary-accent;
      width: 30px;
      text-align: center;
      border-radius: 10px;
      font-size: 0.8em;
      padding: 0.25em 0;
      margin-inline-start: -12.5px;
      top: -20px;
    }

    .range-field__field {
      & input[type="range"] {
        border-radius: 3px;
        border: 1px solid colors.$border-light;
        width: 100%;

        /*Chrome*/
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
          -webkit-appearance: none;
          background-color: colors.$border-light;

          &::-webkit-slider-runnable-track {
            height: 3px;
            -webkit-appearance: none;
            color: colors.$brand-primary-accent;
          }

          &::-webkit-slider-thumb {
            width: 15px;
            -webkit-appearance: none;
            height: 15px;
            cursor: ew-resize;
            background: colors.$brand-primary-accent;
            box-shadow: colors.$boxshadow-light;
            margin-top: -5px;
            border-radius: 100%;
          }
        }

        @supports (-moz-appearance: none) {
          background: white;
          border: none;
        }

        /** FF*/
        &::-moz-range-progress {
          background-color: colors.$border-light;
        }

        &::-moz-range-track {
          background-color: colors.$border-light;
        }

        &::-moz-range-thumb {
          background-color: colors.$brand-primary-accent;
          border-radius: 50%;
          border-color: colors.$brand-primary-accent;
        }

        /* IE*/
        &::-ms-fill-lower {
          background-color: colors.$brand-primary-accent;
        }

        &::-ms-fill-upper {
          background-color: colors.$brand-primary-accent;
        }

        &:focus,
        &:active {
          outline: none;
        }

        &:hover,
        &:focus,
        &:active {
          box-shadow: colors.$boxshadow-light;
        }

        &.error {
          border-color: colors.$color-danger;
        }
      }
    }
  }
}
