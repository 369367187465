@use "~styles/colors";

.group-member-panel__info {
  margin-bottom: 16px;
}

.group-member-panel__info-item {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid colors.$border-light;

  a {
    color: colors.$carolina-blue;
  }
}

.group-member-panel__avatar-icon {
  display: flex;
  margin-inline-end: 9px;
  width: 42px;
  height: 42px;
}

.group-member-panel__icon {
  width: 1em;
  height: 1em;
  margin-inline-end: 1em;
  margin-inline-start: 0.7em;
  svg {
    width: 1em;
    height: 1em;
    path {
      fill: colors.$indigo-dye;
    }
  }
}

.group-member-panel__message {
  font-size: smaller;
}