@use "~styles/animations";
@use "~styles/colors";

.icon-button {
  cursor: pointer;
  transition: animations.$baseTransition;
  border: none;
  background-color: transparent;
  padding: 0;

  svg {
    width: 1.2em;
    height: 1.2em;
  }

  &:hover {
    opacity: 0.6;
  }

  &.blue {
    color: colors.$carolina-blue;
    svg {
      fill: colors.$carolina-blue;
    }
  }
}

.icon-button_value-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}