@use "~styles/colors";

.add-member-form {
  margin-top: 30px;
}

.add-member-form_btn {
  position: absolute;
  bottom: 25px;
  left: 25%;
  width: 228px;
}