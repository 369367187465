@use "~styles/animations";
@use "~styles/colors";
@use "~styles/typography";

.time-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;

  .time-field__label {
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  .time-field__field {
    padding: 1em;
    border-radius: 3px;
    border: 1px solid colors.$border-light;
    width: 100%;
    box-sizing: border-box;

    &::placeholder {
      color: colors.$border-light;
      transition: animations.$baseTransition;
    }

    &:hover {
      border-color: colors.$border-dark;

      &::placeholder {
        color: colors.$border-dark;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: colors.$brand-primary-accent200;

      &::placeholder {
        opacity: 0;
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: colors.$boxshadow-light;
    }

    &.error {
      border-color: colors.$color-danger;
    }
  }

  .time-field__error {
    color: colors.$color-danger;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }

  .time-field__hint {
    color: colors.$text-grey;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: colors.$brand-primary400;
    border: none;
    border-top-left-radius: 0.3rem;
    position: relative;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: colors.$brand-primary400;
  }

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before {
    border-bottom: none;
    border-top-color: colors.$brand-primary400;
  }
  .react-datepicker-popper[data-placement^="top"] {
    .react-datepicker__header {
      display: none;
    }
    .react-datepicker__time-container {
      border-bottom: 14px solid colors.$brand-primary400;
      border-radius: 0px 0px 0px 6px;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    left: 50% !important;
  }

  .react-datepicker {
    font-family: typography.$font-regular;
    border: none;
    box-shadow: colors.$boxshadow-light;
    margin-top: 0.5em;
  }

  .react-datepicker-popper {
    box-shadow: colors.$boxshadow-light;
  }

  .react-datepicker__time-container {
    border-inline-start: none;
    width: 155px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 100%;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    border-bottom-right-radius: 0.3rem;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: colors.$brand-primary400;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker-time__header {
    display: none;
  }
}
