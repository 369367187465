@use "~styles/typography";
.page-title__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.page-title {
  font-family: typography.$font-heading-regular;
  font-weight: 300;
  font-size: 1.4em;
  margin: 0;
}
