@use "styles/colors";

.emoji-container {
  width: max-content;
  margin-bottom: 1rem;

    &.horizontal {
      margin-bottom:0;
    }
}

.emoji-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75px;
  text-align: center;
  gap: 0.5rem;
  position: relative;

  .emoji-box {
    width: var(--emoji-size);
    height: var(--emoji-size);
    line-height: var(--emoji-size);
    text-align: center;
    box-sizing: border-box;
    border-radius: 0.2rem;
    padding: 0;
    font-size: 1.7rem;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &.white {
      background-color: white;
    }

    &.transparent {
      background-color: transparent;
    }
  }

  .emoji-label {
    font-size: 0.8rem;
  }

  &.horizontal {
    flex-direction: row;
    gap: 15px;

      .emoji-box {
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
  }
}

.emoji-container {
  &.condensed {
    margin-bottom: 0;

    .emoji-wrapper {
      max-width: 20px;
    }
  }
}