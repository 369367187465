@use "~styles/colors";
@use "~styles/animations";

.tabs-container {
  display: flex;
  align-items: center;
  align-self: end;
  gap: 7px;

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }
}

.tab-item {
  padding: 0.5em 0.75em;
  background: white;
  border-radius: 0.25em 0.25em 0 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: animations.$baseTransition;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  font-size: 0.8em;

  &.active,
  &:hover {
    border-bottom-color: colors.$brand-primary;
  }

  &.large {
    font-size: 1em;
    padding: 0.75em 1em;
  }
  
  &.medium {
    font-size: 0.875em;
  }
}

.mobile-tabs-container {
  background: white;
  width: calc(100vw - 2rem - 1em);
  padding: 1em 0.5em;

  .mobile-tabs-select {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 0.75em;

      path {
        fill: black;
      }
    }
  }

  .mobile-tabs-menu {
    display: none;
    flex-direction: column;
    padding-top: 1em;

    &.open {
      display: flex;
    }
  }

  .mobile-tab-item {
    width: 100%;
    padding: 0.75em 0;

    &.active {
      color: colors.$brand-primary-accent400;
    }
  }
}
