@use "~styles/colors";

.dashboard-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: white;
  border-radius: 6px;
  box-shadow: colors.$boxshadow-light;
  padding: 1em;
  margin-top: 2em;
}

.dashboard-empty-state__image-holder {
  width: 4em;
  margin-top: 2em;
  animation: floating 10s ease-in-out infinite alternate;
}

.dashboard-empty-state__image {
  width: 100%;
}

@keyframes floating {
  0% {
    transform: scale(1) rotate(0deg);
  }
  100% {
    transform: scale(0.9) rotate(5deg);
  }
}
