@use "~styles/animations";
@use "~styles/colors";

.select {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;

  &.no-mb {
    margin-bottom: 0;
  }

  .select__label {
    font-weight: bold;
    margin-bottom: 0.5em;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  .select__control {
    padding: 0.35em;
    border-radius: 3px;
    border: 1px solid colors.$border-light;

    &::placeholder {
      color: colors.$border-light;
      transition: animations.$baseTransition;
    }

    &:hover {
      border-color: colors.$border-dark;

      &::placeholder {
        color: colors.$border-dark;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: colors.$brand-primary-accent200;

      &::placeholder {
        opacity: 0;
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: colors.$boxshadow-light;
    }

    &.error {
      border-color: colors.$color-danger;
    }
  }

  .select__menu {
    z-index: 100;
  }

  .select__error {
    color: colors.$color-danger;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }

  .select__hint {
    color: colors.$text-grey;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }
}
