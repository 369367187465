@use "~styles/spacing";
@use "~styles/colors";

.app-body {
  width: 100%;
  box-sizing: border-box;
  min-height: 100vh;
  background: colors.$background-grey;
  padding-inline-start: 155px;

  @media (max-width: spacing.$viewport7) {
    padding-inline-start: 0;
    padding-bottom: 56px;
  }
}

.app-content {
  padding: 0.5em 2em;
  flex: 1;
  @media (max-width: spacing.$viewport7) {
    padding: spacing.$app-content-horizontal-padding-mobile
      spacing.$app-content-vertical-padding-mobile;
  }

  &.topnav-content {
    margin-top: 3.5em;

    &.hasAlertBar {
      margin-top: 7.5em;
    }
    @media (max-width: spacing.$viewport7) {
      margin-top: 5em;
      padding: spacing.$app-content-horizontal-padding-mobile
        spacing.$app-content-vertical-padding-mobile;
    }
  }
}
