@use "~styles/colors";
@use "~styles/spacing";
@use "~styles/typography";

.time-title {
    font-size: 16px;
    margin-bottom: 0.5em;
    font-weight: 600;
}

.button_add-time-input {
    margin-bottom: 20px;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42;
    color: colors.$light-blue;
    text-decoration: underline;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.time-field:has(+ button) {
    margin-bottom: 8px;
}