@use "~styles/spacing";
@use "~styles/colors";

.app-workflow-body {
  width: 100%;
  box-sizing: border-box;
  min-height: 100vh;
  background: colors.$background-grey;
  padding-inline-start: 155px;

  @media (max-width: spacing.$viewport7) {
    padding-inline-start: 0;
    padding-bottom: 56px;
  }
}
