@use "~styles/colors";
@use "~styles/spacing";

.auth-code-wrapper {
  margin-bottom: 1em;
}

.auth-code-wrapper__inputs {
  display: flex;
  column-gap: 1.8em;
  @media (max-width: spacing.$viewport12) {
    column-gap: 0.8em;
  }
}

.auth-code-wrapper__input {
  margin-bottom: 0;
}

.auth-code-wrapper__input-field {
  text-align: center;
  font: inherit;
  font-weight: 600;
  padding: 0.7em 0;
  width: 100%;
  box-sizing: border-box;
}

.auth-code-wrapper__error {
  color: colors.$color-danger;
  margin-top: 0.5em;
}
