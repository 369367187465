@use "~styles/colors";
@use "~styles/spacing";

.single-submission-page {
  background: white;
  overflow: auto;
}

.single-submission-page__section-label {
  color: colors.$brand-primary-accent;
}

.single-submission-page__field-label {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.single-submission-page__field {
  margin-bottom: 1.5em;
}

.single-submission-edit-icon {
  width: 1em;
  height: 1em;
  display: block;
  cursor: pointer;
}

.single-submission-edit-label {
  cursor: pointer;
}

.single-submission-edit-label-text {
  margin-bottom: 0;
  margin-inline-start: 0.5em;
}

.single-submission-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.single-submission-header__avatar-icon {
  display: flex;
  margin-inline-end: 9px;
  width: 42px;
  height: 42px;
}
