@use "~styles/colors";

.toggle-default {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &_label {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.42;
    color: colors.$davys-grey;
  }
}

#add_link_form {
  .toggle-default {
    margin-bottom: 20px;
  }
}

