@use "~styles/spacing";
@use "~styles/colors";

.library-page {
  height: 100%;
  flex-wrap: wrap;

  .library-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    width: 100%;
  }

  .library-page-body {
    display: flex;
    padding: 1em;
    flex-direction: column;

    &.library-page-condensed {
      padding: 0em;
    }

    @media (max-width: spacing.$viewport7) {
      padding: 0em;
    }
  }

  .library-page-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    @media (max-width: spacing.$viewport7) {
      justify-content: center;
    }

    .resource-wrapper__date {
      display: none;
    }

    .resource-wrapper__title h3,
    .file-title h3,
    p {
      color: colors.$text-grey100;
    }

  }

  @media (max-width: spacing.$viewport4) {
    padding-top: 1em;
  }
}

.brand-name {
  &_whole-line {
    width: 100%;
  }
}