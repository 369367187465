@use "colors";

$baseTransition: all 0.35s ease-in-out;

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin staggered-z-index {
  @for $i from 1 through 25 {
    &:nth-child(#{$i}) {
      z-index: $i;
    }
  }
}

.spin {
  animation: rotating 1s linear infinite;
}

.bounce {
  animation: bounce 0.5s infinite;
}

.spinner {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.2em dashed colors.$brand-primary;
  animation: rotating 1.5s linear infinite;
  &.small {
    width: 0.5em;
    height: 0.5em;
  }

  &.large {
    width: 2em;
    height: 2em;
  }
}

.transition {
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.33, 1);
}
