@use "~styles/colors";
@use "~styles/animations";
@use "~styles/spacing";
@use "~styles/typography";
.mobile-bottom-nav {
  background: white;
  height: 56px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: animations.$baseTransition;
  position: fixed;
  bottom: 0;
  z-index: 2;
  box-shadow: colors.$boxshadow-light-reverse;

  .mobile-bottom-nav__menu__wrapper {
    width: 100%;
  }

  .mobile-bottom-nav__menu {
    padding: 0;
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}
