@use "~styles/spacing";

.confirm-email-bar__wrapper {
  z-index: 2;
}

.confirm-email-bar {
  display: flex;
  align-items: center;
}

.confirm-email-bar__icon {
  width: 2em;
  height: 2em;
  margin-inline-end: 2em;
  margin-inline-start: 2em;

  svg {
    width: 2em;
    height: 2em;
    path {
      fill: white;
    }
  }
}

.confirm-email-bar__title {
  font-weight: bold;
  margin-bottom: 0;
}

.confirm-email-bar__body {
  margin-bottom: 0;
}

.confirm-email-bar__resend {
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  transition: 0.25s;

  &:hover {
    opacity: 0.8;
  }
}
