.invite-code-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invite-code-footer__links {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
