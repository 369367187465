@use "~styles/spacing";
@use "~styles/colors";
@use "sass:color";

.dashboard-page {
  .dashboard-page__greeting {
    font-size: 28px;
    line-height: 20px;
    color: colors.$text-grey100;
    margin: 24px 0;
  }

  .dashboard-page__wrapper {
    flex-wrap: wrap;

    .dashboard-page__wrapper--widget-primary {
      flex-basis: calc(60% - 1em);
      margin-bottom: 2em;
    }

    .dashboard-page__wrapper--stats--column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: white;
      padding: 1em;
      flex-basis: calc(40% - 5em);
      margin-bottom: 2em;
      border: 1px solid color.adjust(colors.$border-light, $lightness: 5%);
      border-radius: 4px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
      border-top: 3px solid colors.$brand-secondary-accent;
    }

    .dashboard-page__wrapper--stats-title {
      font-size: 1.2em;
      margin-bottom: 0.6em;
    }
  }
}

@media (max-width: spacing.$viewport9) {
  .dashboard-page {
    .dashboard-page__wrapper {
      flex-direction: column-reverse;

      .dashboard-page__wrapper--widget-primary {
        flex-basis: 100%;
      }

      .dashboard-page__wrapper--stats--column {
        flex-basis: 100%;
        flex-direction: row;
      }

      .dashboard-page__wrapper--stats-title {
        display: none;
      }
    }
  }
}

@media (max-width: spacing.$viewport7) {
  .dashboard-page {
    .dashboard-page__wrapper {
      flex-direction: column-reverse;

      .dashboard-page__wrapper--widget-primary {
        flex-basis: 100%;
      }

      .dashboard-page__wrapper--stats--column {
        flex-basis: 100%;
        flex-direction: column;
      }

      .dashboard-page__wrapper--stats-title {
        display: none;
      }
    }
  }
}
