.add_link_form {
  &_title {
    margin: 34px 0;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
  }
  &_button {
    width: 228px !important;
    margin: 0 auto;
    padding: 10px 27px;
  }
}