.color-option {
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  border: 2px solid transparent;
}

.color-icon-label {
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
}

.color-options {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5em;
}