@use "~styles/colors";

.workbook-empty-state {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  border-radius: 6px;
  box-shadow: colors.$boxshadow-light;
  padding: 1em;
}

.workbook-empty-state__image-holder {
  width: 3em;
}

.workbook-empty-state__image {
  width: 100%;
}
