@use "~styles/animations";
@use "~styles/colors";
@use "~styles/typography";

.days_of_week-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  width: 100%;

  .days_of_week-field__label {
    margin-bottom: 0.5em;
    font-weight: 600;
    &::first-letter {
      text-transform: capitalize;
    }

    &.light {
      font-weight: 400;
    }
  }

  .days_of_week-field__field {
    padding: 1em;
    border-radius: 3px;
    border: 1px solid colors.$border-light;
    width: 100%;
    box-sizing: border-box;

    &::placeholder {
      color: colors.$border-light;
      transition: animations.$baseTransition;
    }

    &:hover {
      border-color: colors.$border-dark;

      &::placeholder {
        color: colors.$border-dark;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: colors.$brand-primary-accent200;

      &::placeholder {
        opacity: 0;
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: colors.$boxshadow-light;
    }

    &.error {
      border-color: colors.$color-danger;
    }
  }

  .days_of_week-field__error {
    color: colors.$color-danger;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }

  .days_of_week-field__hint {
    color: colors.$text-grey;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }

  .day-chooser__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5em;
  }

  .day-chooser__option {
    background: colors.$border-light;
    width: 2em;
    height: 2em;
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    cursor: pointer;

    &.selected {
      background: colors.$brand-primary-accent;
    }

    &:hover {
      opacity: 0.8;
      transition: animations.$baseTransition;
    }
  }

  &.disabled {
    .day-chooser__option {
      opacity: 0.6;
      cursor: default;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
