@use "~styles/colors";

.settings-section__qr-code-note {
  color: colors.$color-danger;
  margin-bottom: 0;
}

.settings-section__qr-code-btn {
  margin-top: 1em;
}
