@use "~styles/colors";

.app-redirect-button__overlay {
  position: fixed;
  bottom: 15vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  pointer-events: none;

  .app-redirect-button__button-wrapper {
    display: flex;
    background-color: colors.$background-grey;
    border-radius: 16px;
    box-shadow: colors.$boxshadow-subtle;
    pointer-events: auto;

    .app-redirect-button__open-button {
      display: flex;
      padding: 4px 12px;
      cursor: pointer;

      .app-redirect-button__open-button-icon {
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
          height: 100%;
          fill: colors.$brand-primary;
        }
      }

      .app-redirect-button__open-button-title {
        padding-inline-start: 4px;
        color: colors.$text-grey;
      }
    }

    .app-redirect-button__splitter {
      width: 1px;
      height: 100%;
      background-color: colors.$border-light;
    }

    .app-redirect-button__close-button {
      padding: 4px 12px;
      cursor: pointer;

      svg {
        padding-top: 2px;
        height: 18px;
        fill: colors.$brand-primary-accent200;
      }
    }
  }
}
