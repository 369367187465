@use "~styles/colors";
@use "~styles/animations";
@use "sass:color";

.icon-chooser-wrapper {
  margin-bottom: 1.5em;
}

.icon-chooser-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.chooser-icon {
  width: 2em;
  height: 2em;
  margin-inline-end: 0.5em;
  margin-inline-start: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.5em;
    height: 1.5em;
  }
}

.icon-hint-text {
  font-size: .8em;
  margin-bottom: 1em;
  display: block;
}

.icon-chooser-value {
  margin-bottom: 0;

  .chooser-icon {
    margin-inline-start: 0;
  }
}

.icon-gallery {
  display: flex;
  flex-wrap: wrap;

  .icon-item {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1em;
    text-align: center;
    font-size: .7em;
    border: 2px solid transparent;
    border-radius: 4px;
    padding: .35em;
    transition: animations.$baseTransition;
    cursor: pointer;

    svg {
      width: 3em;
      height: 3em;
    }

    &.selected {
      border-color: black;
      background: black;
      svg {
        path {
          fill: white;
        }
      }
    }

    &:hover {
      border-color: color.adjust(black, $lightness: 10%);
      background: color.adjust(black, $lightness: 10%);

      svg {
        path {
          fill: white;
        }
      }
    }
  }
  
}

.current-icon {
width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1em;
  text-align: center;
  font-size: .7em;

  svg {
    width: 3em;
    height: 3em;
  }
}