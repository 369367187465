@use "~styles/colors";
@use "~styles/animations";

.fields-box-wrapper {
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  .fields-box {
    max-width: 500px;
    box-shadow: colors.$boxshadow-light;
    box-sizing: border-box;
    padding: 1em;
    width: 100%;
  }

  .fields-box-description {
    max-width: 500px;
    box-sizing: border-box;
    padding: 1em;
    width: 100%;
    text-align: center;
  }
}

.assignment-fields-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5em;

  .assignment-fields-navigation__icon {
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 1;
    transition: animations.$baseTransition;

    svg {
      path {
        fill: colors.$brand-primary-accent300;
      }
    }

    &:hover {
      opacity: 0.7;
    }

    &.disabled {
      cursor: default;
      opacity: 0.4;
    }
  }

  .assignment-fields-navigation__title {
    font-weight: bold;
    font-size: 1.2em;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
