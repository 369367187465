@use "~styles/colors";

.settings-section__reminder-btn {
  margin-top: 1.5em;
}

.settings-section__toggle {
  margin-bottom: 1.5em;
}

.settings-section__add-reminder-btn {
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  font: inherit;
  color: colors.$carolina-blue;
  border-bottom: 1px solid colors.$carolina-blue;
  padding: 0;
  margin-bottom: 1.5em;
  margin-top: 0.5em;
}

.patient-reminder-time-field {
  margin-bottom: 0;
}

.time-field-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  .icon-button {
    fill: colors.$carolina-blue;
    scale: 1.4;
    padding: 0 1em;
  }
}
