@use "~styles/colors";
.multi-response-field {
  margin-bottom: 1.5em;
  .multi-response-field__label {
    margin-bottom: 0.5em;
  }
  .multi-response-field__item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 0.3em;
    .list-numbers {
      position: absolute;
      max-height: fit-content;
      top: 25%;
      left: 0.5em;
      color: colors.$text-grey100;
    }
    input {
      padding-left: 2em;
      &:disabled {
        background-color: white;
      }
    }
  }
  .multi-response-field__hint {
    color: colors.$text-grey;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }
}
