@use "~styles/typography";
@use "~styles/colors";
@use "sass:color";

.recent-submissions-card {
  display: flex;
  justify-content: space-between;
  background: white;
  border: 1px solid color.adjust(colors.$border-light, $lightness: 5%);
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  padding: 1em;
  border-top: 3px solid colors.$brand-primary-accent200;

  .recent-submissions-card__left {
    display: flex;
    flex-direction: column;
  }

  .recent-submissions-card__stats {
    background: linear-gradient(
      to right,
      colors.$brand-secondary,
      colors.$brand-primary-accent
    );
    border-radius: 4px;
    text-align: center;
    padding: 0em 1em 0.5em 1em;
    margin-bottom: 0.5em;
    color: white;
    box-shadow: colors.$brand-primary-accent 0px 2px 5px 0px;
  }

  .recent-submissions-card__stats-stat {
    font-family: typography.$font-bold;
    font-weight: 600;
    font-size: 4em;
    line-height: 1em;
  }

  .recent-submissions-card__stats-title {
    font-size: 1.2em;
    margin-bottom: 0.6em;
  }

  .recent-submissions-card__list {
    flex: 1;

    .recent-submissions-card__list-list {
      list-style: none;
      margin: 0;
      padding: 0;
      .recent-submissions-card__list-list--item {
        padding: 0;
      }
    }
  }

  &.primary {
    border-top: 3px solid colors.$brand-primary200;
    .recent-submissions-card__stats {
      background: linear-gradient(
        to right,
        colors.$brand-primary300,
        colors.$brand-primary400
      );
      box-shadow: colors.$brand-primary 0px 2px 5px 0px;
    }
  }

  &.secondary {
    border-top: 3px solid colors.$brand-primary-accent200;
    .recent-submissions-card__stats {
      background: linear-gradient(
        to right,
        colors.$brand-secondary,
        colors.$brand-primary-accent
      );
      box-shadow: colors.$brand-secondary 0px 2px 5px 0px;
    }
  }

  &.primary-accent {
    border-top: 3px solid colors.$brand-primary-accent200;
    .recent-submissions-card__stats {
      background: linear-gradient(
        to right,
        colors.$brand-primary-accent,
        colors.$brand-primary-accent400
      );
      box-shadow: colors.$brand-primary-accent 0px 2px 5px 0px;
    }
  }
}
