@use "~styles/colors";
@use "~styles/animations";

.choose-image-label {
  margin-top: 1.5em;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  height: 250px;
  overflow: auto;
  margin-bottom: 1em;
  border-bottom: 1px solid colors.$border-light;
}

.image-gallery-thumb {
  width: 200px;
  border: 2px solid colors.$border-light;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background: #000;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  .image-gallery-thumb__active-icon {
    display: none;
    flex-shrink: 0;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: colors.$brand-primary-accent300;
    padding: 0.5em;
    align-items: center;
    justify-content: center;
    align-self: baseline;
    margin-top: 0.5em;
    margin-inline-start: 0.25em;
    position: absolute;
    left: 0.25em;

    svg {
      width: 25px;
      height: 25px;

      path {
        fill: white;
      }
    }
  }

  &.active-thumb {
    border-color: colors.$brand-primary-accent300;

    .image-gallery-thumb__active-icon {
      display: flex;
    }
  }
}
