@use "~styles/colors";
@use "~styles/typography";

.submission-data-card {
  background: white;
  box-shadow: colors.$boxshadow-light;
  padding: 1em;
  border-radius: 6px;
  margin-bottom: 1.5em;
  display: flex;
  flex-direction: column;
}

.submission-data-card__name {
  font-size: 1.1em;
  color: colors.$brand-secondary-accent200;
  display: flex;
  align-items: center;
  font-family: typography.$font-bold;
}

.submission-data-card__icon {
  width: 1em;
  height: 1em;
  margin-inline-end: 0.75em;

  svg {
    width: 1em;
    height: 1em;
    path {
      fill: colors.$brand-primary-accent200;
    }
  }
}
.submission-data-card__info {
  border-top: 1px solid colors.$border-light;
  padding-top: 1em;
}
.submission-data-card__info-item {
  font-size: 0.9em;
  color: colors.$text-grey;
  display: flex;
  align-items: center;

  .submission-data-card__icon {
    svg {
      path {
        fill: colors.$brand-secondary-accent200;
      }
    }
  }
}
