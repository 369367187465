@use "~styles/colors";

.divider {
  background: colors.$border-light;

  &.horizontal {
    margin: 1em auto;
    height: 1px;
    width: 100%;
  }

  &.vertical {
    margin: auto 1em;
    width: 1px;
    height: 100%;
    min-height: 20px;
  }

  &.small.horizontal {
    margin: 0.5em auto;
  }

  &.small.vertical {
    margin: auto 0.5em;
  }
}