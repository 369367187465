@use "~styles/typography";
@use "~styles/colors";
@use "~styles/animations";
@use "sass:color";

.submissions-list-item {
  display: flex;
  background: white;
  border: 1px solid color.adjust(colors.$border-light, $lightness: 5%);
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  padding: 1em;
  cursor: pointer;
  transition: animations.$baseTransition;
  margin-bottom: 1em;

  &:hover {
    border-top: 3px solid colors.$brand-primary-accent300;
  }

  .submissions-list-item_icon {
    margin-inline-end: 1em;
    align-items: center;
    display: flex;
  }

  .submissions-list-item_title {
    margin-bottom: 0.5em;
  }

  .submissions-list-item_author {
    margin-bottom: 0;
    font-weight: 400;
  }

  .submissions-list-item_actionPlan {
    color: colors.$text-grey;
    font-weight: 300;
  }
}
