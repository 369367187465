@use "~styles/colors";

.warning-icon {
  width: 1em;
  height: 1em;
  margin-inline-end: 1em;
  background: white;
  padding: 0.25em;
  border-radius: 6px;

  svg {
    width: 1em;
    height: 1em;
    path {
      fill: colors.$color-danger;
    }
  }

  &.small {
    width: 0.75em;
    height: 0.75em;
    margin-inline-end: 0.5em;
    border-radius: 3px;
    display: flex;
    svg {
      width: 0.75em;
      height: 0.75em;
      path {
        fill: colors.$color-danger;
      }
    }
  }

  &.medium {
    width: 1em;
    height: 1em;
    margin-inline-end: 0.5em;
    border-radius: 3px;
    display: flex;
    svg {
      width: 1em;
      height: 1em;
      path {
        fill: colors.$color-danger;
      }
    }
  }
}
