@use "~styles/colors";
@use "sass:color";

.select-field {
  .select__label {
    font-weight: normal;
    &::first-letter {
      text-transform: capitalize;
    }
  }
}

.thought-distortion-option {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid colors.$border-light;

  .select__option {
    padding: 0.5em 1em 1em;

    &:hover,
    &.select__option--is-selected {
      background: color.adjust(colors.$powder-blue, $lightness: 10%);
    }
  }

  .thought-distortion-option__label {
    font-weight: 600;
    color: colors.$text-dark;
    margin-bottom: 0.15em;
  }

  .thought-distortion-option__explanation {
    font-weight: 300;
    color: colors.$text-grey;
    font-size: 1em;
    margin-bottom: 0.75em;
  }

  .thought-distortion-option__example {
    font-size: 0.8em;
    font-style: italic;
    color: colors.$brand-secondary-accent400;
    display: flex;
  }

  .thought-distortion-option__example--icon {
    width: 0.8em;
    height: 0.8em;
    margin-inline-end: 0.8em;
    margin-bottom: 0.2em;

    svg {
      width: 0.8em;
      height: 0.8em;
      path {
        fill: colors.$text-grey;
      }
    }
  }
}
