@use "sass:map";
@use "~styles/colors.scss";
@use "~styles/_layout";
@use "~styles/spacing";

.slide-pane_from_bottom {
  margin-top: 0;
  height: 100%;
}

.slide-pane__content {
  padding: 0;
}

.bottom-modal {
  &_overlay {
    z-index: 999;
  }

  &_header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    background: white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  }

  &_title {
    margin: 0;
    font-size: 28px;
    line-height: 0.71;
    font-weight: 400;
    color: colors.$text-grey100;
    @media screen and (max-width: spacing.$viewport9) {
      font-size: 20px;
      line-height: 1;
    }
  }

  &_button-back {
    padding: 27px 7px 27px 30px;
    &:hover {
      box-shadow: none;
    }
  }

  &_button-close {
    margin-left: auto;
    padding: 27px 25px 27px 38px;
    border-radius: 0;
    box-shadow: -1px 0px 0px 0px rgba(235, 235, 235, 1);
    svg {
      fill: colors.$plum !important;
    }
    &:hover {
      box-shadow: -1px 0px 0px 0px rgba(235, 235, 235, 1);
    }
    @media screen and (max-width: spacing.$viewport9) {
      padding: 27px 5px 27px 10px;
      box-shadow: none;
    }
  }

  &_body {
    min-height: 100vh;
    padding: 21px 0 21px 30px;
    background: #f1f1f1;
    @media screen and (max-width: spacing.$viewport4) {
      justify-content: center !important;
    }
    @media screen and (max-width: spacing.$viewport9) {
      padding: 18px;
    }
  }

  &_form {
    display: none;
    flex-shrink: 0;
    width: 450px;
    padding: 0 24px 24px;
    border-radius: map.get(layout.$spacers, 1);
    background: colors.$text-white;
    box-shadow: colors.$box-shadow-icon;
    @media screen and (min-width: spacing.$viewport9) {
      display: block;
    }
    &__visible {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: Calc(100% - 48px);
      min-height: 100vh;
      height: auto;
      z-index: 9999;
    }
  }

  &_divider {
    margin-right: 22px;
  }

  &_library {
    flex-wrap: wrap;
    @media screen and (max-width: spacing.$viewport4) {
      width: 100%;
      margin: 0 auto;
      justify-content: center;
    }
  }

  .resource-wrapper,
  .file-card,
  .collection_wrapper {
    margin: 0;
    border: 4px solid transparent;
  }

  &_resource {
    width: min-content;
    margin: 0 0 24px 0;
    cursor: pointer;
    @media screen and (min-width: spacing.$viewport4) {
      margin: 0 18px 24px 0;
    }
    .resource-wrapper:first-child {
      box-shadow: colors.$boxshadow-subtle;
    }
    .resource-wrapper__title h3,
    .file-title h3,
    p {
      color: colors.$text-grey100;
    }
    .resource-wrapper__date,
    .file-card__actions {
      display: none;
    }
    margin: 0 0 24px 0;
  }

  &_selected {
    color: colors.$indigo-dye;
    .resource-wrapper:first-child,
    .collection_wrapper:first-child,
    .file-card {
      border: 4px solid colors.$carolina-blue;
      box-shadow: 0px 0px 8px 0px rgba(71, 201, 209, 1);
    }
  }

  .resource-wrapper__actions,
  .collection_actions {
    display: none;
  }

  &_showForm-button {
    display: flex;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    border-radius: 0;
    @media screen and (min-width: spacing.$viewport9) {
      display: none;
    }
  }

  &_hide-form {
    position: absolute;
    top: 0;
    right: 0;
  }

  .file-card {
    margin: 0;
  }

  .file-dropzone {
    min-width: auto;
  }
}
