@use "src/styles/animations";
@use "src/styles/colors";
@use "src/styles/typography";

.date-field,
.time-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  width: 100%;

  .date-field__label,
  .time-field__label {
    margin-bottom: 0.5em;
    font-weight: 600;
  }

  .date-field__field {
    padding: 1em;
    border-radius: 3px;
    border: 1px solid colors.$border-light;
    width: 100%;
    box-sizing: border-box;

    &::placeholder {
      color: colors.$border-light;
      transition: animations.$baseTransition;
    }

    &:hover {
      border-color: colors.$border-dark;

      &::placeholder {
        color: colors.$border-dark;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: colors.$brand-primary-accent200;

      &::placeholder {
        opacity: 0;
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: colors.$boxshadow-light;
    }

    &.error {
      border-color: colors.$color-danger;
    }
  }

  .date-field__error {
    color: colors.$color-danger;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }

  .date-field__hint {
    color: colors.$text-grey;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }

  .react-datepicker__day--today {
    background: colors.$brand-primary200;
    border-radius: 50%;
    font-weight: normal;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background: colors.$brand-primary400;
    border-radius: 50%;
    font-weight: bold;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 50%;
    background: colors.$border-light;
  }

  .react-datepicker__month-container {
    box-shadow: colors.$boxshadow-light;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: colors.$brand-primary400;
    border: none;
    border-top-left-radius: 0.3rem;
    position: relative;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: colors.$brand-primary400;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 0;
  }

  .react-datepicker__navigation--previous {
    border-inline-end-color: white;
  }

  .react-datepicker__navigation--next {
    border-inline-start-color: white;
  }

  .react-datepicker__day-name {
    color: white;
    font-weight: 400;
  }

  .react-datepicker {
    font-family: typography.$font-regular;
    border: none;
    box-shadow: colors.$boxshadow-light;
    margin-top: 0.5em;
  }

  .react-datepicker__current-month {
    color: white;
    font-weight: 100;
    line-height: 1;
    margin-bottom: 0.75em;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    background: white;
    border-radius: 4px;
    padding: 0.15em 0.25em 0.15em 0.15em;
    border-color: transparent;
  }

  div.react-datepicker-popper {
    z-index: 1000 !important;
  }
  .react-datepicker__time-container {
    border-inline-start: none;
    width: 155px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 100%;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    border-bottom-right-radius: 0.3rem;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: colors.$brand-primary400;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker-time__header {
    display: none;
  }
}
