@use "~styles/colors";

.group-name-tag {
  box-sizing: border-box;
  background-color: colors.$peerple;
  color: white;
  max-width: fit-content;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14.4px;
  margin-top: 3px;
}