@use "~styles/colors";

.upgrade-tip {
  &.__react_component_tooltip.show {
    opacity: 1;
    border-radius: 8px;
    box-shadow: colors.$boxshadow-light;
  }

  &.__react_component_tooltip.place-bottom:after {
    inset-inline-start: 80%;
  }

  .upgrade-tip__content {
    width: 300px;
    padding: 0em 1em 1em 1em;
  }
}
