@use "~styles/colors";

.default-to-text {
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  font-size: 16px;
  font-weight: 400;
  height: 35px;
  display: flex;
  align-items: center;
}

.patient-chooser-select {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  // Target the first child div within .patient-chooser-select
  >div {
    // Styles for the Control container

    // Target the second level child divs
    >div {

      // Target the third level child divs
      >div {
        // Styles for the singleValue div
        color: colors.$davys-grey;
        font-weight: 300;
        padding-left: 0;
        margin-left: 0;
      }
    }

    // Target the IndicatorsContainer
    >div {

      // Fifth level targeting for indicator container
      >div {
        svg {
          fill: colors.$davys-grey;
          transition: transform 0.2s;
        }

        &:hover {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    >div {
      >div {
        >div{
          >div{
            padding-left: .75em;
          }
        }
      }
    }
  }
}
