@use "~styles/colors";

.patient-onboarding-slide {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.slide-1 {
    background: colors.$brand-primary-accent200;
  }

  &.slide-2 {
    background: colors.$brand-primary300;
  }

  &.slide-3 {
    background: colors.$color-success;
  }
}
