@use "~styles/colors";

.authentication-footer {
  margin-top: 3em;

  .authentication-footer__line-one {
    color: colors.$text-grey;
    margin-bottom: 0.5em;
    font-size: 0.8em;
    display: block;
  }

  .authentication-footer__line-two {
    color: colors.$text-dark;
    font-weight: bold;
    font-size: 0.8em;
    display: block;
  }
}
