@use "~styles/colors";

.assignment-icon {
  width: 1em;
  height: 1em;
  margin-inline-end: 1em;
  background: colors.$border-light;
  padding: 0.5em;
  border-radius: 6px;
  box-shadow: colors.$box-shadow-icon;

  &.THOUGHT_RECORD {
    background: colors.$brand-primary200;
  }

  &.MOOD_TRACKER {
    background: colors.$brand-primary-accent;
  }
  &.SAFETY_PLAN {
    background: colors.$light-blue;
  }

  &.JOURNAL {
    background: colors.$brand-primary-accent200;
  }

  &.GRATITUDE_JOURNAL {
    background: colors.$brand-primary-accent300;
  }

  &.GOAL_JOURNAL {
    background: colors.$brand-primary-accent400;
  }

  &.WORRY_JOURNAL {
    background: colors.$brand-primary-accent500;
  }

  &.SOCRATIC_QUESTIONS {
    background: colors.$brand-danger-accent100;
  }

  &.COGNITIVE_RESTRUCTURING {
    background: colors.$brand-danger-accent200;
  }

  &.COGNITIVE_TRIANGLE {
    background: colors.$brand-danger-accent300;
  }

  &.UPCOMING_SESSION {
    background: colors.$brand-secondary-accent100;
  }

  &.POST_SESSION {
    background: colors.$brand-secondary-accent200;
  }

  &.SENSES_EXERCISE {
    background: colors.$brand-secondary-accent400;
  }

  svg {
    width: 1em;
    height: 1em;
    path {
      fill: white;
    }
  }

  &.small {
    width: 0.5em;
    height: 0.5em;
    margin-inline-end: 0.5em;
    border-radius: 3px;
    display: flex;
    svg {
      width: 0.5em;
      height: 0.5em;
      path {
        fill: white;
      }
    }
  }

  &.medium {
    width: 1em;
    height: 1em;
    margin-inline-end: 0.5em;
    border-radius: 3px;
    display: flex;
    svg {
      width: 1em;
      height: 1em;
      path {
        fill: white;
      }
    }
  }
}
