@use "src/styles/colors";

.field__error {
  color: colors.$color-success;
  margin-top: 0.5em;
  margin-bottom: 0;
  margin-inline-start: 0.15em;
}

.field__hint {
  color: colors.$text-grey;
  margin-top: 0.5em;
  margin-bottom: 0;
  margin-inline-start: 0.15em;
}
