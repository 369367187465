@use "~styles/colors";
.mobile-page-header {
  background: white;
  height: 42px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 1em;
  box-shadow: colors.$boxshadow-light;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
}

.mobile-page-header__title {
  margin: 0;
}

.mobile-page-header__left {
  margin-inline-end: 1em;
}

.mobile-page-header__right {
  margin-inline-start: 1em;
}
