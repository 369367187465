@use "~styles/spacing";

.website-top-nav {
  align-items: center;
  display: flex;
  height: spacing.$topNavHeight;
  padding-inline-start: spacing.$padding;
  padding-inline-end: spacing.$padding;
  position: fixed;
  width: 100%;
  z-index: spacing.$zIndex;
}
