@use "~styles/colors";
@use "~styles/spacing";

.checkbox-grid-response {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1em;

  &__title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
    text-align: left;
  }

  &__row-label {
    font-size: 0.9em;
    margin-bottom: 0.5rem;
    text-align: left;
  }

  &__row-description {
    font-size: 0.95rem;
    margin-bottom: 0.75rem;
  }

  &__selected-labels {
    font-size: 0.8em;
    margin-top: 0.5em;
    margin-bottom: 1em;
    font-style: italic;
  }

  &__choices {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__circle {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 1px solid colors.$border-light;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    svg {
      display: none;
    }

    &--selected {
      background-color: colors.$carolina-blue;
      color: white;
      border-color: colors.$carolina-blue;

      svg {
        display: block;
        width: 1em;
        height: 1em;

        path {
          fill: white;
        }
      }
    }
  }

  &__row {
    margin-bottom: 0.5em;

    &.horizontal {
      display: flex;
      width: 100%;
      align-items: center;
      min-height: 55px;
      border-bottom: 1px solid colors.$border-light;
      padding: 0.5rem 0;

      &:only-child {
        border-bottom: none;
        min-height: 0px;
      }

      @media (max-width: spacing.$viewport4) {
        flex-direction: column;
        align-items: flex-start;
      }

      .checkbox-grid-response__row-label {
        font-weight: bold;
        flex: 0 0 400px; // Ensures label takes up consistent space
        text-align: left;
        margin-right: 1rem;
        white-space: normal; // Allows text wrapping if needed

        @media (max-width: spacing.$viewport4) {
          flex: 1;
          max-width: 100%;
        }
      }

      .checkbox-grid-response__choices-wrapper {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        gap: 1rem;
        align-items: center;
        min-width: 250px;

        @media (max-width: spacing.$viewport4) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
      }

      .checkbox-grid-response__choices {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;

        @media (max-width: spacing.$viewport4) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
      }

      .checkbox-grid-response__selected-labels {
        font-size: 0.9em;
        font-style: italic;
        flex: 0 0 75px; // Ensures selected labels are aligned
        text-align: left;

        @media (max-width: spacing.$viewport4) {
          text-align: left;
        }
      }
    }
  }

  &__label {
    font-size: 1em;
    color: #555;
    margin-top: 0.5rem;
    text-align: left;
  }
}