@use "sass:map";
@use "~styles/spacing";
@use "~styles/colors";
@use "~styles/animations";
@use "~styles/_layout";

.resource-wrapper {
  box-sizing: border-box;
  width: 300px;
  margin: 1em 1em 2em 0em;
  transition: animations.$baseTransition;
  background: white;
  padding: 1em;
  border-radius: map.get(layout.$spacers, 1);
  max-height: 308px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: colors.$boxshadow-light;

    .resource-wrapper__actions {
      visibility: visible;
      opacity: 1;
    }
  }

  @media (max-width: spacing.$viewport7) {
    margin: 0;
    margin-bottom: 1em;
  }
}

.resource-wrapper__header {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  overflow: hidden;
  height: 48px;
}

.resource-wrapper__icon {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: black;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 5px;

  svg {
    width: 25px;
    height: 25px;
    path {
      fill: white;
    }
  }
}

.resource-wrapper__icon-checked {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: colors.$carolina-blue;
  margin-inline-end: 25px;
  svg {
    width: 16px;
    height: 16px;
    fill: white;
  }
}

.resource-wrapper__title-block {
  display: flex;
  flex-direction: column;
  height: 40px;
  overflow: hidden;
}

.resource-wrapper__title,
.resource-wrapper__title h3 {
  color: colors.$brand-primary-accent;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.18;
  margin: 0;
  transition: animations.$baseTransition;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  &:hover {
    opacity: 0.8;
  }
}

.resource-wrapper__actions {
  visibility: hidden;
  opacity: 0;
  transition: animations.$baseTransition;
  position: absolute;
  inset-inline-end: 0;
  top: 1em;
  z-index: 1;
  background: white;
}

.resource-wrapper__action {
  width: 1em;
  height: 1em;
  cursor: pointer;
  margin-inline-end: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1em;
    height: 1em;
  }
}

.resource-wrapper__date {
  margin: 0;
  color: colors.$text-grey;
}

.resource-wrapper__thumb {
  width: auto;
  max-height: 149px;
  margin-bottom: 1em;
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.24);

  img {
    width: 100%;
    max-height: 170px;
    display: block;
    object-fit: contain;
    transition: animations.$baseTransition;
    &:hover {
      opacity: 0.8;
    }
  }
}

.resource-title,
.resource-title h3 {
  color: colors.$brand-primary-accent;
  font-weight: 300;
  margin: 0;
}

.resource-date {
  margin: 0;
  color: colors.$text-grey;
}

.resource-actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resource-title,
.resource-title h3 {
  color: colors.$brand-primary-accent;
  font-weight: 300;
  margin: 0;
}

.resource-date {
  margin: 0;
  color: colors.$text-grey;
}

.resource-actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resource-wrapper__description {
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.2;
  p {
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
}

.is-library-resource-owner {
  max-width: 185px;
}
