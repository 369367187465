@use "~styles/colors";
@use "~styles/animations";
@use "sass:color";

.add-note-button {
  width: calc(100% - 2em);
  padding: 1em;
  text-align: center;
  border-radius: 4px;
  border: 2px dashed colors.$background-grey;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  color: colors.$brand-primary-accent;
  transition: 0.25s;
  border-radius: 4px;

  &:hover {
    border-color: colors.$brand-primary-accent;
  }

  .add-note-button_plus {
    width: 1.25em;
    height: 1.25em;

    svg {
      width: 1.25em;
      height: 1.25em;
      fill: colors.$brand-primary-accent;
    }
  }
}

.add-note-button-disabled {
  opacity: 0.5;

  &:hover {
    border-color: transparent !important;
    cursor: unset;
  }
}

.add-note-dropdown {
  transition: animations.$baseTransition;
  min-width: 100%;
  position: absolute;
  background: white;
  padding: 0.5em;
  box-sizing: border-box;
  box-shadow: colors.$boxshadow-light;
  border-radius: 0px 0px 4px 4px;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  padding: 0;
}

.add-note-dropdown-visible {
  transition: animations.$baseTransition;
  padding: 0.5em;
  z-index: 1;
  height: auto;
  visibility: visible;
  padding: 0.5;
}

.add-note-dropdown_item {
  display: flex;
  align-items: center;
  padding: 0.5em 0.25em;
  cursor: pointer;
  background: white;
  transition: 0.25s;
  border-radius: 0px;
  border: 1px solid transparent;

  &:hover {
    background: color.adjust(colors.$brand-primary-accent, $lightness: 30%);
    border: 1px solid colors.$border-light;
    border-radius: 4px;
  }
}
