@use "~styles/colors";

.assignments-for-patients-desktop-footer {
  position: fixed;
  inset-inline-start: 56px;
  inset-inline-end: 0;
  bottom: 0;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: flex-end;
  background: white;
  box-shadow: colors.$boxshadow-light-reverse;
}

.assignments-for-patients-desktop-footer__checkboxes {
  display: flex;
  gap: 2em;
}
