.checkbox-grid-options {
  display: flex;
  flex-direction: column;

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 0;
    margin-bottom: 1em;
  }

  .input__label {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.25em;
  }

  .input__hint {
    margin-bottom: 0.5em;
    color: #6c757d;
    font-size: 0.9rem;
  }

  .options-list,
  .rows-list {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &__item {
      display: flex;
      align-items: center;
      border-radius: 6px;
      padding: 0.5em;
      gap: 0.5em;

      &:hover {
        background: #f1f1f1; // Highlight on hover
      }

      .options-list__move-controls,
      .rows-list__move-controls {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.2em;

        .icon-button {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background: #cce5ff;
            transform: scale(1.1); // Slight hover effect
          }
        }
      }

      .input__field {
        flex-grow: 1;
        margin: 0;
        padding: 0.4em;
        font-size: 0.95rem;
        border: 1px solid #ccc;
        border-radius: 4px;

        &:focus {
          border-color: #007bff; // Blue border on focus
          box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
        }
      }

      input[type="number"] {
        max-width: 80px;
        margin-left: 0.5em;
        padding: 0.3em;
      }

      .icon-button.delete {
        color: #dc3545; // Red for delete buttons

        &:hover {
          color: #ff0000; // Darker red on hover
        }
      }
    }

    &__add-button {
      align-self: flex-start;
      margin-top: 0.5em;
    }
  }

  .rows-list__item {
    display: flex;
  }

  .rows-list__add-button {
    align-self: flex-start;
    margin-top: 0.5em;
  }
}