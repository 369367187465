@use "~styles/colors";

.table-wrapper {
  width: 100%;
  overflow-x: auto;
  border-radius: 8px;
  position: relative;
  box-shadow: colors.$boxshadow-subtle;
}

/* The gradient indicators */
.table-scroll-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  pointer-events: none;
  z-index: 1;
  transition: left 0.3s ease, right 0.3s ease;
}

.table-scroll-indicator.left {
  left: 0;
  background: linear-gradient(to right, rgb(242 242 242), rgb(241 241 241 / 0%));
  display: none;
}

.table-scroll-indicator.right {
  right: 0;
  background: linear-gradient(to left, rgb(236 236 236), rgba(255, 255, 255, 0));
  display: none;
}

.data-table-wrapper {
  border-collapse: collapse;
  min-width: 100%;
  background: white;
  border-radius: 8px 8px 0px 0px;
  box-shadow: colors.$boxshadow-light;
}
