@use "src/styles/colors";

.select-field-new {
  .input-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    &:hover,
    &:focus,
    &:active {
      .arrow {
        path {
          fill: colors.$text-grey;
        }
      }
    }
    .arrow {
      position: absolute;
      line-height: normal;
      right: 0;
      padding: 0 1em;
      top: 0;
      transform: translateY(50%);
      border-left: 1px solid colors.$border-light;

      pointer-events: none;
      path {
        fill: colors.$text-grey100;
      }
    }
  }
  input {
    caret-color: transparent;
    cursor: pointer;
  }

  .options-wrapper {
    position: relative;
    width: calc(100% - 0.1em);
    border-radius: 5px;
  }

  .options-list {
    background-color: white;
    width: 100%;
    position: absolute;
    border: 1px solid colors.$border-light;
    display: flex;
    border-radius: 5px;
    top: 0;
    flex-direction: column;
    margin: 0.1em 0 0 0;
    box-shadow: colors.$boxshadow-light;
    z-index: 100;
    max-height: 300px;
    overflow-y: auto;
    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
    button {
      text-align: start;
      padding: 0.5em 1em;
      border: none;
      background-color: transparent;
      font-size: 0.9rem;
    }

    .option,
    .thought-distortion-option {
      cursor: pointer;
      &:hover {
        background-color: colors.$brand-primary-accent200;
      }
    }
  }
}
