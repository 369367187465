@use "~styles/colors";

.reminder-details {
  display: flex;
  align-items: center;
  margin-top: 0.35em;
  height: 1em;

  .reminder-details__icon {
    min-width: 1em;
    display: inline-flex;
    margin-inline-end: 0.35em;
    align-items: center;
    justify-content: center;
    height: 16px;

    svg {
      path {
        fill: colors.$text-grey;
      }
    }
  }

  .reminder-details__time {
    display: inline-flex;
    margin-inline-end: 0.5em;
    font-weight: normal;
    color: colors.$text-grey;
  }

  .reminder-details__days {
    display: inline-flex;
  }

  .reminder-details__day {
    margin-inline-start: 0.25em;
    display: inline-flex;
    font-weight: normal;
    color: colors.$text-grey;
  }
}
