@use "sass:map";
@use "~styles/colors";
@use "~styles/spacing";
@use "~styles/animations";
@use "~styles/_layout";

.file-card {
  box-sizing: border-box;
  border-radius: map.get(layout.$spacers, 1);
  padding: 0.75em 1em;
  margin: 1em 1em 1em 0em;
  width: 300px;
  min-height: 140px;
  max-height: 308px;
  background: white;

  .file-card__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    .file-card-top-line {
      display: flex;
      align-items: center;
    }

    .file-title-block {
      display: flex;
      flex-direction: column;
      height: 66px;
    }

    .file-icon {
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      background: black;
      padding: 0.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline-end: 5px;

      svg {
        width: 25px;
        height: 25px;

        path {
          fill: white;
        }
      }
    }

    .file-icon_checked {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background: colors.$carolina-blue;
      margin-inline-end: 25px;
      svg {
        width: 16px;
        height: 16px;
        fill: white;
      }
    }

    .file-card__actions {
      visibility: hidden;
      opacity: 0;
      transition: animations.$baseTransition;
      position: absolute;
      inset-inline-end: 0;
      top: 0.5em;
      z-index: 1;
      background: white;
      padding: 0 0 0 1em;

      .file-card__actions-action {
        width: 1em;
        height: 1em;
        margin-inline-end: 1em;
        display: flex-inline;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          width: 1em;
          height: 1em;
        }
      }
    }

    .file-card-title {
      font-weight: 400;
      white-space: pre-wrap;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;

      h3 {
        margin: 0;
        font-size: 16px;
        line-height: 1.18;
        font-weight: 400;
      }
    }

    .file-date {
      margin: 0;
      font-size: 14px;
      line-height: 1.18;
      font-weight: 400;
    }

    .file-card-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .file-description {
      margin: 1.5em 0 0 0;
      overflow: hidden;
      font-size: 14px;
      line-height: 1.2;
      p {
        white-space: pre-wrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
    
    }

    .file-card-in-use {
      text-align: start;
      font-size: 0.8em;
    }
  }

  &:hover {
    box-shadow: colors.$boxshadow-light;

    .file-card__actions {
      visibility: visible;
      opacity: 1;
    }
  }

  &_preview img {
    max-height: 149px;
    object-fit: cover;
  }

  &_header {
    height: 48px;
  }
}

@media (max-width: spacing.$viewport4) {
  .file-card {
    margin: 0;
    margin-bottom: 1em;
  }
}
