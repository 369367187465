@use "typography";
@use "spacing";
@use "animations";
@use "colors";
@use "layout";
@use "sharedComponents";

@use "sass:color";

// Brand
.logo-primary {
  fill: colors.$brand-primary-accent200;
}

.logo-secondary {
  fill: white;
}

// Basics/Layouts
html {
  scroll-behavior: smooth;
  @media (max-width: spacing.$viewport7) {
    &.js-focus-visible {
      font-size: 14px;
    }
  }
}

body {
  margin: 0;
  font-family: typography.$font-regular;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section {
  padding-top: spacing.$padding;
  padding-bottom: spacing.$padding;
  padding-inline-start: spacing.$padding * 2;
  padding-inline-end: spacing.$padding * 2;

  @media (min-width: spacing.$viewport12) {
    padding-inline-start: spacing.$padding * 4;
    padding-inline-end: spacing.$padding * 4;
  }
}

.flex {
  display: flex;
  flex-direction: row;

  &.inline-flex {
    display: inline-flex;
  }

  &.col {
    flex-direction: column;
  }

  &.align-center {
    align-items: center;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-between,
  &.space-between {
    justify-content: space-between;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }
}

.no-mt {
  margin-top: 0;
}

.no-mb {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.5em;
}
.mb-2 {
  margin-bottom: 1em;
}

.mt-1 {
  margin-top: 0.5em;
}

.mt-2 {
  margin-top: 1em;
}

.mt-n1 {
  margin-top: -0.75em;
}

.mr-05 {
  margin-right: 0.5em;
}
.ml-05 {
  margin-left: 0.5em;
}

.ml-1 {
  margin-left: 1em;
}

.ml-15 {
  margin-left: 1.5em;
}

.ml-2 {
  margin-left: 2em;
}

.mr-1 {
  margin-right: 1em;
}

.mr-2 {
  margin-right: 2em;
}

.p-y-1 {
  padding-bottom: 1em;
  padding-top: 1em;
}

.p-b-1 {
  padding-bottom: 1em;
}

.force-to-bottom {
  align-self: end;
}

.gap-15 {
  gap: 1.5em;
}

// Responsive

@media (max-width: #{spacing.$viewport7 - 1}) {
  .mobile-column {
    flex-direction: column;
  }
}

@media (max-width: #{spacing.$viewport7 - 1}) {
  .sm-1 {
    width: spacing.$c1;
  }
  .sm-2 {
    width: spacing.$c2;
  }
  .sm-3 {
    width: spacing.$c3;
  }
  .sm-4 {
    width: spacing.$c4;
  }
  .sm-5 {
    width: spacing.$c5;
  }
  .sm-6 {
    width: spacing.$c6;
  }
  .sm-7 {
    width: spacing.$c7;
  }
  .sm-8 {
    width: spacing.$c8;
  }
}

@media (min-width: spacing.$viewport7) {
  .mlg-1 {
    width: spacing.$c1;
  }
  .mlg-2 {
    width: spacing.$c2;
  }
  .mlg-3 {
    width: spacing.$c3;
  }
  .mlg-4 {
    width: spacing.$c4;
  }
  .mlg-5 {
    width: spacing.$c5;
  }
  .mlg-6 {
    width: spacing.$c6;
  }
  .mlg-7 {
    width: spacing.$c7;
  }
  .mlg-8 {
    width: spacing.$c8;
  }
}

@media (min-width: spacing.$viewport9) {
  .t-1 {
    width: spacing.$c1;
  }
  .t-2 {
    width: spacing.$c2;
  }
  .t-3 {
    width: spacing.$c3;
  }
  .t-4 {
    width: spacing.$c4;
  }
  .t-5 {
    width: spacing.$c5;
  }
  .t-6 {
    width: spacing.$c6;
  }
  .t-7 {
    width: spacing.$c7;
  }
  .t-8 {
    width: spacing.$c8;
  }
}

@media (min-width: spacing.$viewport12) {
  .d-1 {
    width: spacing.$c1;
  }
  .d-2 {
    width: spacing.$c2;
  }
  .d-3 {
    width: spacing.$c3;
  }
  .d-4 {
    width: spacing.$c4;
  }
  .d-5 {
    width: spacing.$c5;
  }
  .d-6 {
    width: spacing.$c6;
  }
  .d-7 {
    width: spacing.$c7;
  }
  .d-8 {
    width: spacing.$c8;
  }
}

.v4AndUp {
  display: none;
  @media (min-width: spacing.$viewport4) {
    display: flex;
  }
}

.v7AndUp {
  display: none;
  @media (min-width: spacing.$viewport7) {
    display: flex;
  }
}

.v9AndUp {
  display: none;
  @media (min-width: spacing.$viewport9) {
    display: flex;
  }
}

.v12AndUp {
  display: none;
  @media (min-width: spacing.$viewport12) {
    display: flex;
  }
}

// Animations

.transition {
  transition: animations.$baseTransition;
  -moz-transition: animations.$baseTransition;
  -webkit-transition: animations.$baseTransition;
  -o-transition: animations.$baseTransition;
  -ms-transition: animations.$baseTransition;
  --transition: animations.$baseTransition;
}

// Typography

html {
  font-size: 16px;
}

a,
.pseudo-link {
  color: colors.$brand-primary-accent;
  text-decoration: none;
  transition: animations.$baseTransition;

  &:hover {
    color: color.adjust(colors.$brand-primary400, $lightness: 10%);
  }

  &:active,
  &:focus {
    color: colors.$brand-primary-accent400;
  }
}

h1 {
  &.h1-1 {
    font-size: 2.2em;
    margin-bottom: 0.5em;
    @media (max-width: spacing.$viewport7) {
      font-size: 1.8em;
    }
  }
}

h2 {
  &.h2-1 {
    font-size: 1.5em;
    margin-bottom: 2em;
    @media (max-width: spacing.$viewport7) {
      font-size: 1em;
    }
  }

  &.h2-2 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  &.h2-3 {
    font-size: 3em;
    margin-bottom: 0.5em;
  }
}

h3 {
  &.h3-2 {
    font-size: 1.3em;
    margin-bottom: 0.5em;
  }
}

p {
  margin-top: 0;
  font-size: 1em;

  &.p-2 {
    font-size: 1.2em;
  }
}

.underlined {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.light-text {
  color: colors.$text-grey100;
}

.white-text,
a.white-text {
  color: white;
}

.text-center {
  text-align: center;
}

.carolina-blue-text {
  color: colors.$carolina-blue;
}

// 3rd-party Overrides
.custom-toast-progress {
  &.Toastify__progress-bar--default {
    background: colors.$brand-primary-accent;
  }
}

@media (max-width: spacing.$viewport7) {
  div.commandbar-checklist {
    bottom: 80px;
  }
}

.image-preview {
  object-fit: cover;
  padding: 1em;
  border: 1px dashed colors.$brand-primary-accent200;
}

.border-bottom {
  border-bottom: 1px solid colors.$border-light;
}

.border-top {
  border-top: 1px solid colors.$border-light;
}

.border-left {
  border-left: 1px solid colors.$border-light;
}

.border-right {
  border-right: 1px solid colors.$border-light;
}

.label{
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
}
