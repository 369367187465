.attached-image-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &.thumb {
    width: 200px;
    margin-bottom: 0em;
  }

  .attached-image {
    width: 100%;

    &.thumb {
      margin-bottom: 0em;
    }
  }
}
