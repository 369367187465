@use "~styles/colors";
.avatar-wrapper {
  border-radius: 50%;
  background: colors.$brand-primary-accent500;
  width: 100%;
  height: 100%;
  max-width: 10em;
  max-height: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  svg {
    width: 1.25em;
    height: 1.25em;
    max-width: 20px;

    path {
      fill: white;
    }
  }
}
