@use "~styles/animations";
@use "~styles/colors";
@use "~styles/spacing";

.mood-scale-field {
  border: 0px solid transparent;

  &.mood-scale-field_wrapper-error {
    border: 1px solid colors.$color-success;
    border-radius: 1em;
    padding: 1em;
    margin-bottom: 1em;
  }

  .mood-scale-field__label {
    font-weight: bold;
  }

  .mood-scale-field__icon-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2em 0;
    justify-content: space-between;
    max-width: 300px;

    @media (max-width: spacing.$viewport7) {
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
  }

  .mood-scale-field__icon-choice {
    min-width: 3em;
    padding: 0.75em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 5em;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 1em;
    transition: animations.$baseTransition;
    background: white;

    &:hover,
    &.selected {
      transition: animations.$baseTransition;
      border: 1px solid colors.$border-dark;
      box-shadow: colors.$boxshadow-subtle;
    }

    .mood-scale-field__icon-label {
      padding: 0.25em 0.5em;
      border-radius: 8em;
      border: 1px solid colors.$border-dark;
      margin: 0;
      width: auto;
      min-width: 100%;
      transition: animations.$baseTransition;
      font-size: 0.8em;
    }

    .mood-scale-field__icon {
      width: 3em;
      height: 3em;
      margin: 0em 0.5em 1.5em;
      transition: animations.$baseTransition;

      svg {
        width: 3em;
        height: 4em;
        transition: animations.$baseTransition;

        path {
          fill: black;
          transition: animations.$baseTransition;
        }
      }
    }

    &.awesome {
      transition: animations.$baseTransition;
      .mood-scale-field__icon-label {
        color: colors.$awesome;
        border-color: colors.$awesome;
      }

      .mood-scale-field__icon {
        svg {
          path {
            fill: colors.$awesome;
          }
        }
      }

      &:hover,
      &.selected {
        transition: animations.$baseTransition;
        background: colors.$awesome;
        border-color: colors.$awesome;

        .mood-scale-field__icon-label {
          color: white;
          border-color: white;
        }

        .mood-scale-field__icon {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    &.great {
      .mood-scale-field__icon-label {
        color: colors.$great;
        border-color: colors.$great;
      }

      .mood-scale-field__icon {
        svg {
          path {
            fill: colors.$great;
          }
        }
      }

      &:hover,
      &.selected {
        transition: animations.$baseTransition;
        background: colors.$great;
        border-color: colors.$great;

        .mood-scale-field__icon-label {
          color: white;
          border-color: white;
        }

        .mood-scale-field__icon {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    &.meh {
      .mood-scale-field__icon-label {
        color: colors.$meh;
        border-color: colors.$meh;
      }

      .mood-scale-field__icon {
        svg {
          path {
            fill: colors.$meh;
          }
        }
      }
      &:hover,
      &.selected {
        transition: animations.$baseTransition;
        background: colors.$meh;
        border-color: colors.$meh;

        .mood-scale-field__icon-label {
          color: white;
          border-color: white;
        }

        .mood-scale-field__icon {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    &.sad {
      .mood-scale-field__icon-label {
        color: colors.$sad;
        border-color: colors.$sad;
      }

      .mood-scale-field__icon {
        svg {
          path {
            fill: colors.$sad;
          }
        }
      }

      &:hover,
      &.selected {
        transition: animations.$baseTransition;
        background: colors.$sad;
        border-color: colors.$sad;

        .mood-scale-field__icon-label {
          color: white;
          border-color: white;
        }

        .mood-scale-field__icon {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    &.not-ok {
      .mood-scale-field__icon-label {
        color: colors.$not-ok;
        border-color: colors.$not-ok;
      }

      .mood-scale-field__icon {
        svg {
          path {
            fill: colors.$not-ok;
          }
        }
      }

      &:hover,
      &.selected {
        transition: animations.$baseTransition;
        background: colors.$not-ok;
        border-color: colors.$not-ok;

        .mood-scale-field__icon-label {
          color: white;
          border-color: white;
        }

        .mood-scale-field__icon {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
  }

  .mood-scale-field__error {
    color: colors.$color-success;
    margin-top: 0.5em;
    margin-bottom: 1em;
    margin-inline-start: 0.15em;
  }

  .mood-scale-field__hint {
    color: colors.$text-grey;
    margin-top: 0.5em;
    margin-bottom: 1em;
    margin-inline-start: 0.15em;
  }
}
