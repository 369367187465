@use "~styles/colors";

.file-card-condensed {
  background: white;
  display: flex;
  align-items: center;
  border: 1px solid colors.$border-light;
  padding: 1em 0.5em;
  border-radius: 4px;
  cursor: pointer;

  .file-card-condensed__title {
    font-weight: bold;
    overflow: hidden;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;

    h4 {
      margin: 0;
    }
  }

  &:hover {
    box-shadow: colors.$boxshadow-light;
  }

  .file-card-condensed__file-icon {
    flex-shrink: 0;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: black;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 1em;

    svg {
      width: 25px;
      height: 25px;

      path {
        fill: white;
      }
    }
  }

  &.file-card__selected {
    border-color: colors.$brand-primary-accent300;

    .file-card-condensed__title {
      color: colors.$brand-primary-accent300;
    }

    .file-card-condensed__file-icon {
      background: colors.$brand-primary-accent300;
    }
  }
}
