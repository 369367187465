@use "../FileUploader/FileUploader";
@use "~styles/colors";

.file-name,
.selected-file__inner-blank {
  .image-icon {
    margin-bottom: 0.5em;
    svg {
      width: 2em;
      height: 2em;
      path {
        fill: colors.$indigo-dye;
      }
    }
  }
}

.selected-file__inner-blank {
  border: none;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
