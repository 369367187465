@use "~styles/colors";
@use "~styles/spacing";

$fields-margin-top: 0.5em;

.fields-box-for-patients-wrapper {
  display: flex;
  justify-content: center;
  margin-top: $fields-margin-top;
  margin-bottom: 5em;

  .fields-for-patients-box {
    max-width: 500px;
    box-shadow: colors.$boxshadow-light;
    box-sizing: border-box;
    padding: 1em;
    background: white;
    width: 100%;
  }

  .fields-for-patients-description {
    margin-bottom: 0.5rem;
  }
}

.collapse-box-for-patients-wrapper {
  display: flex;
  justify-content: center;
  margin-top: $fields-margin-top;
  @media (max-width: spacing.$viewport4) {
    position: -webkit-sticky;
    position: sticky;
    top: 56px;
    z-index: 10;
    margin: calc(-#{spacing.$app-content-horizontal-padding-mobile})
      calc(-#{spacing.$app-content-vertical-padding-mobile}) 0;
  }

  .collapse-box-for-patients {
    max-width: 500px;
    width: 100%;
  }
}
.flat-button {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1em;
  font-weight: bold;
  min-width: 100px;
  text-align: center;
  justify-content: center;
  border: none;
  outline: none;

  &:not([disabled]) {
    background: white;
    color: black;
    cursor: pointer;
  }
}
