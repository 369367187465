.patient-option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .patient-avatar-wrapper {
    width: 2em;
    height: 2em;
    min-width: 2em;
    margin-inline-end: 1em;
  }
  .patient-option-label {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
