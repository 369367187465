@use "~styles/colors";

.pinned.collapse {
  position: relative;
  background: #e8e8e8;
  svg {
    height: 22px;
    width: 22px;

    path {
      fill: colors.$gold;
    }
  }

  .collapse-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4d4d4d;
  }
  .collapse-content {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-inline-start: 1rem;

    color: #4d4d4d;
  }

  .collapse-icon {
    position: absolute;
    top: 0.7rem;
    right: 1rem;

    cursor: pointer;
  }
}
