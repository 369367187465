@use "~styles/colors";

.file-removal-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;

  .file-removal-confirmation__icon {
    width: 2em;
    height: 2em;
    margin-bottom: 0.5em;

    svg {
      path {
        fill: colors.$color-danger;
      }
    }
  }

  .file-removal-confirmation__title {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 1em;
  }

  .file-removal-confirmation__buttons {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
    align-items: center;
    width: 250px;
    justify-content: space-between;
  }
}
