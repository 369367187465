@use "~styles/spacing";

.side-panel {
  transition: 0.25s;
  z-index: 999;
  background: white;
  padding: 0.5em;
  box-sizing: border-box;
  box-shadow: -6px 0px 20px 20px #00000036;
  max-width: 100vw;

  .slide-pane__content {
    padding: 0em 1.25em;
  }
}

.side-panel__header {
  margin-bottom: 1.5em;
  padding: 1em 0em 0.5em;
}

.side-panel__header.flex {
  align-items: center;
}

.side-panel__header-title {
  margin: 0;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.side-panel__header-icon {
  width: 1.5em;
  height: 1.5em;
  padding-inline-end: 0.5em;
  margin-inline-end: 0.75em;
  cursor: pointer;

  svg {
    width: 1.5em;
    height: 1.5em;
  }
}

.side-panel-background {
  backdrop-filter: blur(2px);
  z-index: 2;

  &.no-bg {
    backdrop-filter: none;
    background: rgb(0 0 0 / 19%);
  }
}

@media (min-width: spacing.$viewport4) {
  .side-panel {
    min-width: spacing.$viewport4 !important;
    max-width: 100vw;
  }
}

@media (max-width: spacing.$viewport4) {
  .side-panel {
    width: spacing.$viewport4 !important;
    max-width: 100vw;
  }
}
