@use "~styles/colors";
@use "sass:color";

.user-menu {
  position: relative; // This keeps the dropdown positioned relative to the user menu container.
}

.user-menu__title {
  display: flex;
  align-items: center;
  svg {
    width: .75em;
    height: .75em;
    margin-left: .5em;

    path {
      fill: black;
    }
  }
}

.user-menu__header {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font: inherit; // Inherit font styles from parent
  color: inherit; // Inherit color from parent
}

.user-menu__dropdown {
  position: absolute;
  top: calc(100% + 5px); // Position below the header
  right: 0;
  z-index: 10; // Ensure it appears above other elements
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Adds a subtle shadow for depth
  border-radius: 4px; // Rounded corners
  padding: 0.5em 0; // Padding around the content
  min-width: 150px; // Ensures a minimum width for the dropdown
}

.user-menu-item__dot {
  height: 0.4em;
  width: 0.4em;
  background: #f75970;
  border-radius: 100%;
  position: absolute;
  inset-inline-end: 0.35em;
  box-shadow: 0px 0px 4px 1px #f75970;
}

.user-menu__avatar {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .5em;

  font-size: .8em;

  svg {
    margin-left: 0;
    width: 1em;
    height: 1em;

    path {
      fill: white;
    }
  }
}

.user-menu__list {
  list-style: none;
  margin: 0;
  padding: 0;

  .user-menu__list-item {
    text-align: start;
    cursor: pointer;
    padding: 0.5em 1em;
    transition: 0.25s;

    &:hover {
      background: color.adjust(colors.$color-info, $lightness: 8%);
    }

    .user-menu__button {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      text-align: start;
      width: 100%;
      display: flex;
      font: inherit; // Inherit font styles from parent
      color: inherit; // Inherit color from parent
      align-items: center;
      justify-content: space-between;

      .user-menu-item__icon {
        svg {
          width: .75em;
          height: .75em;
          margin-left: .5em;
      
          path {
            fill: black;
          }
        }
      }
    }
  }
}