.infobox_display_field {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
}

.infobox_display_field.align-left .content {
  display: flex;
  align-items: center;
}

.infobox_display_field.align-right .content {
  display: flex;
  align-items: center;
}

.infobox_display_field.align-top .content {
  display: block;
  text-align: center;
}

.infobox_display_field .icon {
  font-size: 24px;
}

.infobox_display_field.align-left .icon {
  margin-right: 24px;
}

.infobox_display_field.align-right .icon {
  margin-left: 24px;
}

.infobox_display_field.align-top .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}