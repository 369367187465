@use "~styles/typography";
@use "~styles/colors";

.logo {
  font-family: typography.$font-logo-bold;
  display: flex;
  align-items: center;

  .logo__text {
    font-size: 2em;
    margin: 0;
    color: colors.$brand-primary;
  }

  &.white {
    .logo__text {
      font-size: 3em;
      margin: 0;
      color: white;
      height: 50px;
    }
  }
}
