@use "~styles/animations";
@use "~styles/colors";

.string-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;

  .string-field__label {
    margin-bottom: 0.5em;
  }

  .string-field__field {
    padding: 1em;
    border-radius: 3px;
    border: 1px solid colors.$border-light;

    &::placeholder {
      color: colors.$border-light;
      transition: animations.$baseTransition;
    }

    &:hover {
      border-color: colors.$border-dark;

      &::placeholder {
        color: colors.$border-dark;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: colors.$brand-primary-accent200;

      &::placeholder {
        opacity: 0;
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: colors.$boxshadow-light;
    }

    &.error {
      border-color: colors.$color-success;
    }
  }

  .string-field__error {
    color: colors.$color-success;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }

  .string-field__hint {
    color: colors.$text-grey;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }
}
