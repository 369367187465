@use "~styles/colors";

.local-modal-overlay {
  background: rgba(0, 0, 0, 0.32);
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-enter {
  opacity: 0;
}

.overlay-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.overlay-exit {
  opacity: 1;
}

.overlay-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.local-modal-inner {
  background: white;
  padding: 1em;
  border-radius: 6px;
  box-shadow: colors.$boxshadow-light;
  text-align: center;
  z-index: 11;
  font-weight: normal;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.animated-modal-enter {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.9);
}

.animated-modal-enter-active {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 300ms, transform 300ms;
}

.animated-modal-exit {
  opacity: 1;
}

.animated-modal-exit-active {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  transition: opacity 300ms, transform 300ms;
}
