@use "~styles/colors";
@use "~styles/animations";

.generic-layout {
  width: 100%;
  background: white;
  min-height: 100vh;
}

.generic-layout-header {
  padding: 1em 2em 0em 2em;
  background: colors.$indigo-dye;
}

.generic-layout-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
