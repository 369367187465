@use "~styles/colors";
@use "~styles/animations";

.data-table__search-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: .5em;
}

.data-table__search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.75em;
  height: 0.75em;
  margin-inline-end: -1.25em;
  margin-bottom: 0.15em;

  svg {
    width: 0.75em;
    height: 0.75em;

    path {
      fill: colors.$brand-primary-accent300;
    }
  }
}

.data-table__search-field {
  transition: animations.$baseTransition;
  border: none;
  border: 2px solid colors.$border-light;
  background: transparent;
  padding: .5em;
  padding-inline-start: 1.75em;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    border-color: colors.$brand-primary-accent300;
    outline: transparent;
  }
}