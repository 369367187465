@use "~styles/colors";
@use "sass:color";

.not-found-page {
  text-align: center;
  height: 100vh;

  .headline {
    color: colors.$text-dark;
  }

  .subline {
    font-weight: 300;
  }
}
