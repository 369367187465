@use "~styles/colors";

.score-card {
  border: 1px solid colors.$border-light;
  display: flex;
  padding-top: .75em;
  padding-right: .75em;
  padding-bottom: .25em;
  padding-left: 1em;
  border-radius: 6px;
  margin-bottom: 1em;

  .score-card__score {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 1.25em;

    .score-card__scoreValue {
      margin: 0;
      font-size: 2em;
    }

    .score-card__scoreLabel {
      text-transform: uppercase;
      font-size: .75em;
    }
  }

  .score-card__instructions {

    .score-card__instructionsLabel {
      font-weight: bold;
      margin-bottom: .5em;
      display: block;
    }

  }
}