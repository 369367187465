@use "~styles/colors";

$pinned-fields-padding-left: 1rem;
$pinned-fields-padding-top: 12px;
$pinned-fields-padding-right: 1rem;
$pinned-fields-padding-bottom: 12px;

.collapse {
  position: relative;
  padding: $pinned-fields-padding-top $pinned-fields-padding-right
    $pinned-fields-padding-bottom $pinned-fields-padding-left;
  background: linear-gradient(
      97.27deg,
      colors.$pinned-fields-third 7.25%,
      colors.$pinned-fields-second 89%
    ),
    colors.$pinned-fields-main;

  svg {
    height: 22px;
    width: 22px;

    path {
      fill: colors.$pinned-fields-icon;
    }
  }

  .collapse-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: colors.$text-white;
  }

  .collapse-content {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-top: $pinned-fields-padding-top;
    padding-bottom: $pinned-fields-padding-bottom;

    color: colors.$text-white;
  }

  .collapse-icon {
    position: absolute;
    right: $pinned-fields-padding-right;
    top: $pinned-fields-padding-top;
    cursor: pointer;
  }
}
