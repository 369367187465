.options-list {
  display: flex;
  flex-direction: column;
  margin: 0.5em 0.5em 1em 0.5em;
}

.options-list__add-button {
  margin-top: 0.5em;
}

.options-list__move-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.options-list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.3em 0;
  padding: 0;
  .input__field {
    flex-grow: 1;
    margin: 0 0.5em;
  }
  .icon-button {
    margin: 0.24em 0 0.24em 0;
    scale: 1.4;
  }
}
