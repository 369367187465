@use "~styles/colors";
.video-field {
  padding: 0.5em 0 1.5em;
}

.video-field__label {
  margin-bottom: 0.5em;
  font-weight: 600;
}

.video-field-empty {
  min-width: 350px;
  max-width: 380px;
  border: 2px dashed #ccc;
  padding: 20px;
  transition: border 0.3s ease-in-out;
  text-align: center;
  border-radius: 4px;
}

.video-field-empty__icon {
  margin-bottom: 0.5em;
  svg {
    width: 2em;
    height: 2em;
    path {
      fill: colors.$indigo-dye;
    }
  }
}
