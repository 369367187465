@use "~styles/colors";

.authentication-header {
  .authentication-header__headline {
    color: colors.$text-grey;
    margin-bottom: 0.25em;
  }

  .authentication-header__subline {
    color: colors.$text-grey;
    font-weight: 300;
    margin-top: 1.25em;
    margin-bottom: 1em;
  }
}
