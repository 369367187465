@use "~styles/colors";
@use "~styles/animations";

.section-label {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  &_text {
    margin: 0 auto 0 5px;
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    color: colors.$davys-grey;
  }
}

.field_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;

  .input {
    margin-bottom: 12px;
  }
}

.field_first-line {
  width: 100%;
}

.field_description {
  font-size: 14px;
  font-weight: 500;
  color: colors.$davys-grey;
  // opacity: 0.56;
}

.label {
  &_container {
    display: flex;
    align-items: center;
  }

  &-text {
    display: inline-flex;
    padding-left: 11px;
    font-size: 16px;
    font-weight: 700 !important;
    line-height: 1.84;
    color: colors.$davys-grey;
  }

  &-text_hidden {
    opacity: 0.35;
  }
}
